<template>
  <div>
    <div>
      {{ selectedLinkedFighter }}
      <v-btn @click="linkFighter(selectedLinkedFighter, slug)">
        Link Fighter
      </v-btn>
      <v-autocomplete
        v-model="allFighters"
        :loading="loadingFighters"
        :items="fighterItems"
        :search-input.sync="fighterSearch"
        item-text="name"
        cache-items
        class="mx-4"
        flat
        hide-no-data
        hide-details
        :deletable-chips="true"
        :multiple="false"
        label="Search Fighters?"
      >
        <template v-slot:selection="{ attr, on, item, fighterItems }">
          <v-chip
            v-bind="attr"
            :input-value="fighterItems"
            color="blue-grey"
            class="white--text"
            v-on="on"
          >
            <span v-text="item.name"></span>
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content @click="selectedLinkedFighter=item">
            <v-list-item-title v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle v-text="item.slug"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>
<script>
import dbFunctions from '@/db'

export default {
  props: {
    slug: String,
  },
  data() {
    return {
      loadingFighters: false,
      fighterItems: [],
      allFighters: {},
      fighterSearch: null,
      selectedLinkedFighter: '',
    }
  },
  watch: {
    fighterSearch() {
      // Items have already been loaded
      if (this.fighterItems.length > 0) return

      this.loadingFighters = true

      dbFunctions.getAllFighters().then(res => {
        const fighterData = []
        res.forEach(doc => {
          const data = doc.data()
          fighterData.push({name: data.name, id: data.fighter_id, slug: doc.id})
        })
        this.fighterItems = fighterData
      })
    },
  },
  methods: {
    linkFighter(fighter, newUfcSlug) {
      console.log(fighter)
      console.log(newUfcSlug)
      const fields = {ufc_slug: newUfcSlug}
      dbFunctions.updateFighter(fighter.slug, fields)
    },
  },
}
</script>
