<template>
  <div>
    <div class="text-center">
      <div
        :class="{ 'mobile': isMobile}"
        class="ma-1 pb-0 pick-card"
      >
        <v-row class="mt-0">
          <v-col
            cols="12"
            class="col-md-4 mx-auto px-2 pb-0 mb-2 pt-2 mt-0 "
            :class="{'order-last':orderlast}"
          >
            <div v-if="isMobile">
              <div v-if="side==='fighter_1'">
                <v-chip
                  text-color="white"
                  large
                >
                  <div
                    class="mx-2 ml-4 me-1 text-wrap text-right f1text"
                    v-html="name"
                  >
                  </div>
                  <v-avatar
                    v-if="fighter.fight_results"
                    :size="gAvatar"
                    round
                    class="pick-avatar pb-0 mb-0 ml-2"
                    :class="{ borderwin: (fighter.fight_results.winner && fighter.fight_results.winner == side && !fighter.fight_results.draw && !fighter.fight_results.cancelled), borderloss: (fighter.fight_results.winner && fighter.fight_results.winner != side && !fighter.fight_results.draw && !fighter.fight_results.cancelled) }"
                  >
                    <v-img
                      :width="gAvatar"
                      :height="gAvatar"
                      lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                      :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                    ></v-img>
                  </v-avatar>

                  <v-avatar
                    v-else
                    :size="gAvatar"
                    round
                    class="pick-avatar pb-0 mb-0 ml-2"
                  >
                    <v-img
                      lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                      :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                    ></v-img>
                  </v-avatar>
                </v-chip>
                <div v-if="flag">
                  <country-flag
                    :country="flag"
                    size="normal"
                    rounded
                    class="mobile pick-flag pb-0 mb-0 mx-auto text-center flag1"
                  />
                </div>
              </div>
              <div v-else>
                <v-chip
                  text-color="white"
                  large
                >
                  <v-avatar
                    v-if="fighter.fight_results"
                    :size="gAvatar"
                    round
                    class="pick-avatar pb-0 mb-0"
                    :class="{ borderwin: (fighter.fight_results.winner && fighter.fight_results.winner == side && !fighter.fight_results.draw && !fighter.fight_results.cancelled), borderloss: (fighter.fight_results.winner && fighter.fight_results.winner != side && !fighter.fight_results.draw && !fighter.fight_results.cancelled) }"
                  >
                    <v-img
                      :width="gAvatar"
                      :height="gAvatar"
                      lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                      :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                    ></v-img>
                  </v-avatar>

                  <v-avatar
                    v-else
                    :size="gAvatar"
                    round
                    class="pick-avatar pb-0 mb-0"
                  >
                    <v-img
                      lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                      :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                    ></v-img>
                  </v-avatar>
                  <div
                    class="mx-2 ml-3 me-1 text-wrap text-left f2text"
                    v-html="name"
                  >
                  </div>
                </v-chip>
                <div v-if="flag">
                  <country-flag
                    :country="flag"
                    size="normal"
                    rounded
                    class="mobile pick-flag pb-0 mb-0 mx-auto text-center flag2"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <h3
                v-if="isMobile"
                class="text-center d-all fk-name text-truncate mb-2"
                v-html="name"
              ></h3>
              <v-avatar
                v-if="fighter.fight_results"
                :size="gAvatar"
                round
                class="pick-avatar mx-auto pb-0 mb-0"
                :class="{ borderwin: (fighter.fight_results.winner && fighter.fight_results.winner == side && !fighter.fight_results.draw && !fighter.fight_results.cancelled), borderloss: (fighter.fight_results.winner && fighter.fight_results.winner != side && !fighter.fight_results.draw && !fighter.fight_results.cancelled) }"
              >
                <v-img
                  lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                  :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                ></v-img>
              </v-avatar>
              <v-avatar
                v-else
                :size="gAvatar"
                round
                class="pick-avatar mx-auto pb-0 mb-0"
              >
                <v-img
                  lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                  :src="fighterImagesURL+'fighters/images/head-'+slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
                ></v-img>
              </v-avatar>
              <div v-if="flag">
                <country-flag
                  :country="flag"
                  size="normal"
                  rounded
                  class="pick-flag pb-0 mb-0 mx-auto text-center"
                />
              </div>
              <div v-else>
                <br>
              </div>
            </div>

            <div
              v-if="!fighter.fight_results || !fighter.fight_results.winner && !fighter.fight_results.draw"
              class="pstreaklist streak text-truncate"
              :class="{'order-last':orderlast}"
            >
              <div v-if="streak.length>0">
                <span
                  class="text-capitalize"
                  content="+"
                >
                  +
                </span>
                <span
                  v-for="(item, idx) in streak"
                  :key="idx"
                  class="text-capitalize"
                  :content="item"
                  :class="wl[item]"
                >
                  {{ item }}
                </span>
              </div>
              <span v-else>
                --
              </span>
            </div>
            <div
              v-else-if="fighter.fight_results"
              class="text-center mx-auto pb-0 fighter-results"
            >
              <v-chip
                v-if="fighter.fight_results.winner == side && !fighter.fight_results.draw && !fighter.fight_results.cancelled"
                class="mt-5 text-center mx-auto mb-0 pb-0"
                outlined
                color="success"
              >
                Won
              </v-chip>
              <v-chip
                v-else-if="!fighter.fight_results.draw && fighter.fight_results.winner && !fighter.fight_results.cancelled"
                class="mt-5 text-center mx-auto mb-0 pb-0"
                outlined
                color="error"
              >
                Lost
              </v-chip>
              <v-chip
                v-else-if="fighter.fight_results.draw"
                class="mt-5 text-center mx-auto mb-0 pb-0"
                outlined
                color="primary"
              >
                Draw
              </v-chip>
            </div>

            <div
              v-if="fighter.fight_results && fighter.fight_results.missedWeight == side"
              class="mt-0 pt-0 text-center mx-auto text-xs warning--text missed-weight"
            >
              Missed weight
            </div>
          </v-col>
          <v-col
            cols="12"
            class="text-center col-md-8 mt-2 pt-0 mb-1"
          >
            <h3
              v-if="!isMobile"
              class="text-center d-all fk-name text-truncate"
              v-html="name"
            ></h3>
            <p class="text-xs hidden-sm-and-down alias mb-3 mt-0">
              <span v-if="alias">"{{ alias }}"</span>
            </p>
            <v-chip class="text-xs pa-3">
              W<span class="hidden-sm-and-down">ins</span>:{{ win }}
              L<span class="hidden-sm-and-down">osses</span>:{{ loss }}
              D<span class="hidden-sm-and-down">raws</span>:{{ draw }}
            </v-chip>
            <v-card-actions class="mx-auto mt-2 pa-0 pa-0 pickca">
              <div
                class="betButtons mx-auto hidden"
                :class="{ 'powerupsDisabled': powerupDisabled}"
              >
                <v-btn
                  outlined
                  small
                  :loading="powerUpCreator"
                  :class="{'haspu': count }"
                  class="mt-1 mr-1 ml-1 pa-2 ma-0 mb-2"
                  @click="openPowerUpCreator(fighter, side)"
                >
                  PowerUps
                  <span
                    v-if="count"
                    class="pu-count mb-3"
                    :class="count===4 ? 'complete' : ''"
                  >{{ count }}
                  </span>
                </v-btn>
              </div>
              <div
                class="betButtons mx-auto hidden"
                :class="{ 'bettingDisabled': bettingDisabled}"
              >
                <div
                  v-if="!fighter.fight_results || !fighter.fight_results.winner && !fighter.fight_results.draw"
                >
                  <div class="hidden-sm-and-down">
                    <v-btn
                      outlined
                      small
                      class="mt-1 mr-1 ml-1 pa-2 ma-0 d-none"
                    >
                      Buy NFT
                    </v-btn>
                    <v-btn
                      v-if="isLoggedin"
                      outlined
                      small
                      class="mt-1 mr-1 ml-1 pa-2 ma-0"
                      @click="placeBet(fighter)"
                    >
                      Place Bet
                    </v-btn>
                  </div>
                  <div class="hidden-md-and-up">
                    <v-btn
                      outlined
                      small
                      class="mt-1 mr-1 ml-1 pa-2 ma-0"
                      @click="placeBet(fighter)"
                    >
                      Place Bet
                    </v-btn>
                  </div>
                </div>
                <div v-else-if="fighter.fight_results">
                  <v-btn
                    v-if="fighter.fight_results.winner == side && !fighter.fight_results.draw && fighter.bet"
                    outlined
                    small
                    class="mt-2"
                  >
                    Claim Bet
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
            <v-col
              v-if="fighter.fight_results"
              cols="12"
              class="text-center mt-1 pt-0 pb-1 mb-0"
            >
              <v-chip
                v-if="fighter.fight_results.bonusType == 'FON'"
                outlined
                color="primary"
              >
                Fight of the Night
              </v-chip>

              <v-chip
                v-if="fighter.fight_results.bonusType == 'PON' && fighter.fight_results.winner == side"
                outlined
                color="primary"
                class="text-truncate"
              >
                <span v-if="isMobile">
                  Perf. of the Night
                </span>
                <span v-else>
                  Performance of the Night
                </span>
              </v-chip>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </div>
    <Modal
      :modal="modal"
    ></Modal>
    <v-dialog
      v-if="powerUpCreator"
      v-model="powerUpCreator"
      class="overflow-hidden"
      width="800"
      height="600"
    >
      <PowerUps
        v-if="powerUpCreator"
        :key="selectedFighter.id"
        :fighter="selectedFighter"
        :pick="true"
        :all-picks="myPicks"
        :pu-loading="puLoading"
        :event-slug="eventSlug"
        :date="date"
        :status="status"
        :fight-id="fightId"
        :fighter-index="fighterIndex"
        :side="side"
        :all-fighters="allFighters"
        :allUsedPowerUps="allUsedPowerUps"
        @closePowerUp="closePowerUpParent"
      >
      </PowerUps>
    </v-dialog>
  </div>
</template>

<script>
import {mdiAlert, mdiCheck} from '@mdi/js'
import Modal from '@/views/components/Modal.vue'
import PowerUps from '@/views/components/PowerUps.vue'

export default {
  components: {
    Modal,
    PowerUps,
  },
  props: {
    fighter: Object,
    home: Boolean, // fighter1
    away: Boolean, // fighter2
    admin: Boolean,
    rosterLoaded: Boolean,
    eventSlug: String,
    myPicks: Array,
    date: String,
    status: String,
    fightId: Number,
    fighterIndex: Number,
    pucount: Number,
    allFighters: Object,
    isMobile: Boolean,
    allUsedPowerUps: Object,
  },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiAlert,
      },
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      ufcFightId: '',
      bettingDisabled: true,
      powerupDisabled: false,
      puLoading: true,
      dapperUser: null,
      streak: '',
      wl: {w: 'success--text', l: 'error--text'},
      side: '',
      orderlast: true,
      slug: '',
      isLoggedin: '',
      gAvatar: 65,
      name: '',
      chosen: '',
      win: '',
      loss: '',
      draw: '',
      alias: '',
      flag: '',
      modalVisible: false,
      modal: {
        title: 'Place a Bet',
        show: false,
        chatButton: true,
        content: 'Betting is not enabled for your account.<br> If you would like early access please contact us.',
      },
      powerUpCreator: false,
      count: 0,
      closePowerUp: false,
      selectedFighter: {},
    }
  },
  watch: {
    powerUpCreator: {
      handler() {
        this.count = this.pucount
      },
    },
  },
  mounted() {
    this.onResize()
    this.loadFighters()
    this.isLoggedin = this.$store.state.user?.isLoggedin
    if (this.user?.profile.dapperAddress) {
      this.dapperUser = this.user.profile.dapperAddress
    }
    window.removeEventListener('resize', this.onResize, {passive: true})
    this.count = this.pucount
  },
  methods: {
    onResize() {
      if (this.isMobile) {
        this.gAvatar = 30
      }
    },
    openPowerUpCreator(fighter) {
      if (!this.dapperUser) {
        this.$router.push({name: 'powerups', query: {return: this.$route.path}})
      } else {
        this.count = this.pucount
        if (this.side === 'fighter_1') {
          this.selectedFighter = fighter.fighter_1_data
        } else {
          this.selectedFighter = fighter.fighter_2_data
        }
        this.powerUpCreator = true
      }
    },
    closePowerUpParent(reload, count) {
      this.count = this.pucount
      this.powerUpCreator = false
      this.count = count
      if (reload) {
        setTimeout(() => {
          this.powerUpCreator = true
        }, 10)
      }
    },
    loadFighters() {
      if (this.home) {
        this.side = 'fighter_1'
        this.orderlast = true
        this.ufcFightId = this.fighter.ufc_fight_id
        this.data = this.fighter.fighter_1_data
        this.flag = this.fighter.fighter_1_data?.country
        this.slug = this.fighter.fighter_1_data?.slug
        if (this.fighter.fighter_1_data?.streak) {
          this.streak = this.fighter.fighter_1_data?.streak.substring(0, 9).split(',')
        }
        if (this.isMobile) {
          this.orderlast = true
          this.name = this.fighter.fighter_1
          this.alias = this.fighter.fighter_1_data?.nickname
        } else {
          this.name = this.fighter.fighter_1
          this.alias = this.fighter.fighter_1_data?.nickname
        }
        if (window.innerWidth < 1000) {
          this.orderlast = false
        }
        if (this.fighter.fighter_1_data) {
          this.chosen = this.fighter.fighter_1_data_chosen
          this.win = this.fighter.fighter_1_data.win
          this.loss = this.fighter.fighter_1_data.loss
          this.draw = this.fighter.fighter_1_data.draw
        }
      } else {
        this.side = 'fighter_2'
        this.orderlast = false
        this.data = this.fighter.fighter_2_data
        this.flag = this.fighter.fighter_2_data?.country
        this.slug = this.fighter.fighter_2_data?.slug
        if (this.fighter.fighter_2_data?.streak) {
          this.streak = this.fighter.fighter_2_data?.streak.substring(0, 9).split(',')
        }
        if (this.isMobile) {
          this.orderlast = false
          this.name = this.fighter.fighter_2
          this.alias = this.fighter.fighter_2_data?.nickname
        } else {
          this.name = this.fighter.fighter_2
          this.alias = this.fighter.fighter_2_data?.nickname
        }
        if (this.fighter.fighter_2_data) {
          this.chosen = this.fighter.fighter_2_data_chosen
          this.win = this.fighter.fighter_2_data.win
          this.loss = this.fighter.fighter_2_data.loss
          this.draw = this.fighter.fighter_2_data.draw
        }
      }
    },
    placeBet(fighter) {
      console.log(fighter.ufc_fight_id)
      this.modal.show = true
      window.$crisp?.push(['set', 'session:event', [[['placeBet', {status: 'opened'}, 'blue']]]])
    },
  }
  ,
}
</script>

<style lang="scss" scoped>

.f1text, .f2text {
  width: 96px;
}

.mobile .f1text, .f2text {
  font-size: 11px;
  line-height: 14px;
}

.f2text {
  width: 106px;
}

.eventCancelled .v-avatar, .eventCancelled .streak, .eventCancelled h3, .eventCancelled .v-chip,
.eventCancelled .pick-flag, .eventCancelled .alias, .eventCancelled .v-icon {
  filter: grayscale(1) !important;
  opacity: 0.3;
}

.eventCancelled button.v-btn:not(.haspu) {
  filter: grayscale(1);
  opacity: 0.3;
}

.fighter-results {
  margin-top: -10px;
  line-height: 7px !important;
}

span.pick-flag.mobile.normal-flag {
  position: relative;
  margin: auto;
  transform: scale(0.35);
  top: -6px;
  margin-bottom: -25px !important;
}

.pick-card .v-chip.v-size--large {
  width: 180px;
  margin: 0;
  padding: 7px 3px 7px 3px;
}

.mobile .pick-card .v-chip.v-size--large {
  height: 45px;
}

.missed-weight {
  font-size: 9px !important;
}

p.text-warning {
  color: #f79803;
}

span.pu-count {
  background: #312d4b;
  border: 2px dotted #1abadb;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  right: -18px;
  position: absolute;
  top: -15px;
  font-size: 11px;
  line-height: 15px;
  opacity: 0;
}

span.pu-count.complete {
  border: 2px dotted #56ca00;
}

.chosen span.pu-count {
  opacity: 1;
}

.mobile .fk-name {
  min-height: 27px;
}

.betButtons.bettingDisabled, .powerupsDisabled {
  // opacity: 0.5;
  display: none;
}

span.pick-flag {
  margin-top: -20px;
  border-radius: 9px;
}

.alias {
  font-style: italic;
  margin: -5px 0px 3px 0;
  height: 12px;
}

.borderloss {
  border: 2px solid #ff4c51;
}

.borderwin {
  border: 2px solid #56ca00
}

.v-badge.streak > span {
  margin: 5px 0 !important;
  z-index: 2;
  position: relative;
  opacity: 0.8;
}

.pstreaklist {
  background: #28243d;
  height: 25px;
  border-radius: 6px;
  z-index: 1;
  position: relative;
  max-width: 84px;
  text-align: center;
  margin: auto;
  display: block;
}

.mobile .pstreaklist {
  top: 9px;
}

.pstreaklist.streak span {
  font-size: 10px !important;
  margin: -0.5px;
}
</style>
