<template>
  <div>
    <v-card class="py-2 pick-main">
      <div class="ma-1 mr-3 position-absolute dfs-settings">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              icon
              small
              class="me-n3 mt-n2 mr-4"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiCogOutline }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                class="mx-auto"
                @click="resetPicks"
              >
                Reset All Picks & PowerUps
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-row>
        <v-col cols="12">
          <div class="text-center mt-8 mx-auto">
            <v-skeleton-loader
              v-if="!ufcEvent.name"
              class="mx-auto"
              type="list-item-two-line"
              width="300px"
            ></v-skeleton-loader>

            <div v-else>
              <h3>{{ ufcEvent.name }}</h3>
              <div class="d-flex flex-column">
                <span class="d-block font-weight-semibold text--primary text-truncate">
                  {{ ufcEvent.date | moment("dddd MMM Do") }},
                  {{ ufcEvent.date | moment("h:mm a") }} <small>{{ tz }}</small></span>
              </div>
            </div>
            <div
              itemscope
              itemtype="https://schema.org/BroadcastEvent"
            >
              <span
                itemprop="name"
                :content="ufcEvent.name"
              ></span>
              <span
                itemprop="description"
                :content="ufcEvent.name"
              ></span>
              <meta
                itemprop="startDate"
                :content="ufcEvent.date"
              >
              <meta
                itemprop="location"
                :content="ufcEvent.location"
              />
              <link
                itemprop="isLiveBroadcast"
                href="https://schema.org/True"
              />
              <span
                itemprop="startDate"
                :content="ufcEvent.date"
              ></span>
            </div>

            <span class="d-block font-weight-semibold text--primary text-truncate mb-1 mt-2">Prize Pool</span>
            <v-avatar
              size="25"
              class="mr-0 mr-1"
            >
              <v-img
                src="@/assets/images/buy/KombatCoinSilver.png"
                class="float-left mt-0"
              ></v-img>
            </v-avatar>
            <span
              v-if="ufcEvent.prize_pool"
              class="mr-2"
            >
              {{ ufcEvent.prize_pool|toCurrencyNoDec }}
            </span>
            <span
              v-else
              class="mr-2"
            >
              1,750,000
            </span>
            <p
              class="text-md mt-3"
            >
              Select the fighters you think will win.
            </p>
            <v-btn
              outlined
              small
              @click="showPoints=!showPoints"
            >
              View Rules
            </v-btn>
            <br>
            <br>
            <v-card-text v-if="showPoints">
              <v-sheet
                class="text-sm col-8 offset-2"
              >
                <div class="text-center my-6">
                  <a
                    href="https://support.fantasykombat.com/en/article/how-to-play-fantasykombat-1jf829i/"
                    target="_blank"
                  >
                    <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                    Overview of how to play</a>
                </div>

                <div class="text-center my-6">
                  <a
                    href="https://support.fantasykombat.com/en/article/how-our-fantasy-sport-scoring-works-arp794/"
                    target="_blank"
                  >
                    <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                    How our Fantasy Sport scoring works</a>
                </div>

                <div class="text-center my-6">
                  <a
                    href="https://support.fantasykombat.com/en/article/strike-powerups-1n5ez5c/"
                    target="_blank"
                  >
                    <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                    How Strike PowerUps work</a>
                </div>
              </v-sheet>
            </v-card-text>

            <div v-if="admin">
              <v-chip class="text-center mb-3">
                {{ eventStatus || "Upcoming" }}
              </v-chip>
              <br>
              <v-btn
                outlined
                class="mr-4"
                @click="updateResults(false)"
              >
                Update Results
              </v-btn>
              <v-btn
                :loading="updateLoader"
                outlined
                class="mr-4"
                @click="updateResults(true)"
              >
                Update Fighters
              </v-btn>
              <br>
              <br>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-divider class="pb-6"></v-divider>
      <div
        v-if="!ufcEvent.eventId & rosterLoaded"
        class="text-center mx-auto my-5"
      >
        Fight Details to TBC
      </div>
      <div
        v-if="!rosterLoaded"
        class="mb-12"
      >
        <v-row class="mx-auto px-4">
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-auto px-4">
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-auto px-4">
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
          <v-col
            cols="6"
          >
            <div class="text-center">
              <v-skeleton-loader
                type="article"
              ></v-skeleton-loader>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="rosterLoaded">
        <div
          v-for="(item, value, index) in roster"
          :key="index"
        >
          <div
            v-if="item.fight_results && totalPoints"
            class="mt-0 text-center hidden-sm-and-up"
          >
            <v-chip
              v-if="item.fight_results.draw"
              outlined
            >
              Draw
            </v-chip>
          </div>
          <div
            v-if="item.points && totalPoints"
            class="text-center mt-0 mb-4 hidden-sm-and-up"
          >
            <v-chip
              outlined
              color="success"
            >

              Points: {{ item.points + item.powerUpPoints }}
            </v-chip>
            <br>
            <span v-if="item.powerUpPoints" class="ml-1 text-xs">
                ({{ item.points }} Points + {{ item.powerUpPoints }} PowerUps)
              </span>
          </div>
          <div
            v-if="item.cancelled"
            class="text-center mt-0 mb-4 hidden-md-and-up"
          >
            <v-chip
              outlined
              disabled
              color="error"
            >
              Canceled
            </v-chip>
          </div>
          <div
            v-else-if="!item.points && isMobile && ufcEvent.status === 'Live'"
            class="text-center hidden-md-and-up mb-4 mt-0 pt-0">
            <v-chip
              v-if="item.fight_status"
              outlined
              :color="statusColor[item.fight_status]"
            >
                  <span
                    v-if="item.fight_status =='Final' || item.fight_status =='Over'"
                  >Finished</span>
              <span v-else>{{ item.fight_status }}</span>
            </v-chip>
          </div>
          <v-row>
            <v-col
              cols="6"
              class="col-sm-6 col-md-4 offset-md-1"
              :class="{ eventCancelled: item.cancelled }"
            >
              <div class="text-center">
                <div v-if="!item.fighter_1_data && admin">
                  <span>{{ item.fighter_1 }}<br>{{ item.fighter_1_slug }}</span>
                  <FigherAutoComplete :slug="item.fighter_1_slug"></FigherAutoComplete>
                </div>
                <v-card
                  v-if="item.fighter_1_data"
                  class="text-center pa-0 f-card"
                  :class="(item.picked === 'fighter_1') ? 'chosen' : 'f-card'"
                  @click="selectToWin(value, item.ufc_fight_id, '1', item.fighter_1)"
                >
                  <v-icon
                    v-if="item.picked === 'fighter_1' && item.confirmed"
                    class="f1-check"
                  >
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <v-icon
                    v-else-if="item.picked === 'fighter_1'"
                    class="f1-check spinner"
                  >
                    {{ icons.mdiLoading }}
                  </v-icon>
                  <v-card-text class="pa-1">
                    <FighterPick
                      v-if="item.fighter_1_data && rosterLoaded"
                      :admin="admin"
                      :fighter="item"
                      :home="true"
                      :roster-loaded="rosterLoaded"
                      :event-slug="eventSlug"
                      :my-picks="myPicks"
                      :date="ufcEvent.date"
                      :status="ufcEvent.status"
                      :fight-id="item.ufc_fight_id"
                      :fighter-index="value"
                      :all-used-power-ups="allUsedPowerUps"
                      :pucount="item.powerUps"
                      :all-fighters="allFighters"
                      :is-mobile="isMobile"
                    ></FighterPick>
                    <v-skeleton-loader
                      v-else
                      type="article"
                    ></v-skeleton-loader>
                  </v-card-text>
                </v-card>
                <div
                  v-if="admin"
                  class="mt-7 mx-auto"
                >
                  <v-btn
                    :loading="updateLoader"
                    x-small
                    outlined
                    @click="addUpdateFighter(item.fighter_1_slug)"
                  >
                    Auto Add/Update
                  </v-btn>
                  <v-btn
                    :loading="updateLoader"
                    x-small
                    class="ml-2"
                    outlined
                    @click="editResultModal(item, value, 1)"
                  >
                    Add Results
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col
              cols="0"
              class="hidden-sm-and-down col-sm-0 col-md-2"
            >
              <div class="text-center mt-0">
                <h2 v-if="item.order===1">
                  Main Event
                </h2>
                <p
                  v-if="item.cancelled"
                  class="error--text"
                >
                  Fight Canceled
                </p>
                <p
                  v-if="item.order!==1"
                  class="ma-0 pa-0 text-sm wiht"
                >
                  {{ item.segment|card }}
                </p>
                <p
                  v-if="ufcEvent.version == 2"
                  class="ma-0 pa-0 text-sm wiht"
                >
                  {{ item.weight_class|weightClass }}
                </p>

                <v-btn
                  outlined
                  small
                  class="mt-2 mb-4"
                  @click="openHeadtoHead(item)"
                >
                  Head-to-head
                </v-btn>
                <br>

                <div
                  v-if="item.points && totalPoints"
                  class="text-center"
                >
                  <v-chip
                    outlined
                    color="success"
                  >
                    Your Points: {{ item.points }}
                  </v-chip>
                  <br>
                  <span v-if="item.powerUpPoints" class="ml-1 text-xs">
                ({{ item.points }} Points + {{ item.powerUpPoints }} PowerUps)
              </span>
                </div>
                <v-chip
                  v-else-if="item.fight_status"
                  outlined
                  :color="statusColor[item.fight_status]"
                >
                  <span
                    v-if="item.fight_status =='Final' || item.fight_status =='Over'"
                  >Finished</span>
                  <span v-else>{{ item.fight_status }}</span>
                </v-chip>
              </div>
              <div
                itemprop="broadcastOfEvent"
                itemscope
                itemtype="https://schema.org/SportsEvent"
              >
                <span
                  itemprop="name"
                  :content="item.weight_class"
                ></span>
                <span
                  itemprop="logo"
                  content="https://www.fantasykombat.com/FK_logo.png"
                ></span>
                <span
                  itemprop="organizer"
                  content="UFC"
                ></span>
                <span
                  itemprop="sport"
                  content="MMA"
                ></span>
                <span
                  itemprop="contributor"
                  content="FantasyKombat.com"
                ></span>
                <span
                  itemprop="sponsor"
                  content="FantasyKombat.com"
                ></span>
                <span
                  itemprop="url"
                  :content="url"
                ></span>
                <span
                  itemprop="name"
                  :content="ufcEvent.name"
                ></span>
                <meta
                  itemprop="location"
                  :content="ufcEvent.location"
                />
                <span
                  itemprop="startDate"
                  :content="ufcEvent.date"
                ></span>
                <div
                  itemprop="competitor"
                  itemscope
                  itemtype="https://schema.org/SportsPerson"
                >
                  <meta
                    itemprop="name"
                    :content="item.fighter_1"
                  />
                </div>
                <div
                  itemprop="competitor"
                  itemscope
                  itemtype="https://schema.org/SportsPerson"
                >
                  <meta
                    itemprop="name"
                    :content="item.fighter_2"
                  />
                </div>
              </div>
              <div
                v-if="item.fight_results"
                class="mt-0 text-center text-sm"
              >
                <v-chip
                  v-if="item.fight_results.draw"
                  outlined
                >
                  Draw
                </v-chip>
                <p
                  v-if="!item.fight_results.draw && item.fight_results.method"
                  outlined
                  class="ma-2"
                >
                  {{ item[item.fight_results.winner] }} won by "{{ item.fight_results.method }}"
                </p>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="col-sm-6 col-md-4"
              :class="{ eventCancelled: item.cancelled }"
            >
              <div v-if="!item.fighter_2_data && admin">
                <span>{{ item.fighter_2 }}<br>{{ item.fighter_2_slug }}</span>
                <FigherAutoComplete
                  :fighter="item"
                  :slug="item.fighter_2_slug"
                ></FigherAutoComplete>
              </div>
              <v-card
                v-if="item.fighter_2_data"
                class="text-center pa-0"
                :class="(item.picked === 'fighter_2') ? 'chosen' : 'f-card'"
                @click="selectToWin(value, item.ufc_fight_id, '2', item.fighter_2)"
              >
                <v-icon
                  v-if="item.picked === 'fighter_2' && item.confirmed"
                  class="f2-check"
                >
                  {{ icons.mdiCheck }}
                </v-icon>
                <v-icon
                  v-else-if="item.picked === 'fighter_2'"
                  class="f2-check spinner"
                >
                  {{ icons.mdiLoading }}
                </v-icon>
                <v-card-text class="pa-1">
                  <FighterPick
                    v-if="item.fighter_2_data && rosterLoaded"
                    :admin="admin"
                    :fighter="item"
                    :away="true"
                    :roster-loaded="rosterLoaded"
                    :event-slug="eventSlug"
                    :my-picks="myPicks"
                    :date="ufcEvent.date"
                    :status="ufcEvent.status"
                    :fight-id="item.ufc_fight_id"
                    :fighter-index="value"
                    :all-used-power-ups="allUsedPowerUps"
                    :pucount="item.powerUps"
                    :all-fighters="allFighters"
                    :is-mobile="isMobile"
                  ></FighterPick>
                  <v-skeleton-loader
                    v-else
                    type="article"
                  ></v-skeleton-loader>
                </v-card-text>
              </v-card>
              <div
                v-if="admin"
                class="mt-7 mx-auto"
              >
                <v-btn
                  x-small
                  outlined
                  @click="addUpdateFighter(item.fighter_2_slug)"
                >
                  Auto Add/Update
                </v-btn>

                <v-btn
                  x-small
                  class="ml-2"
                  outlined
                  @click="editResultModal(item, value, 2)"
                >
                  Add Results
                </v-btn>
              </div>
            </v-col>
            <div class="mx-auto text-center hidden-md-and-up mt-1">
              <v-btn
                outlined
                small
                @click="openHeadtoHead(item)"
              >
                Head-to-head
              </v-btn>
            </div>
            <v-col cols="12">
              <v-divider class="pb-5"></v-divider>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="ffleague">
        <div class="text-center">
          <h3>Fantasy Fighter League</h3>
          <h4>Coming Soon!</h4>
          <v-btn
            class="ma-2"
            outlined
            small
            @click="ffPicker=true"
          >
            More info
          </v-btn>
        </div>
        <br>
        <div class="grey-disable">
          <v-row>
            <v-col
              cols="6"
              class="col-md-5 col-sm-6"
            >
              <div v-if="ffSelectedFighter[0]">
                <h4 class="text-center">
                  {{ ffSelectedFighter[0].name }}
                </h4>
                <p class="text-xs text-center">
                  "{{ ffSelectedFighter[0].alias }}"
                </p>
              </div>
              <div v-else>
                <br>
                <br>
              </div>
              <div
                class="outter ff can--catch"
                @click="fantasyFighterPick"
              >
                <div
                  v-if="ffSelectedFighter[0]"
                  class="inner"
                  :style="ffbg"
                >
                </div>
                <div v-else>
                  <v-icon
                    class="add-icon"
                    large
                  >
                    {{ icons.mdiPlusCircleOutline }}
                  </v-icon>
                  <div
                    v-if="ffSelectedFighter"
                    class="inner"
                  ></div>
                  <div class="inner">
                  </div>
                </div>
              </div>
              <div>
                <country-flag
                  v-if="ffSelectedFighter[0]"
                  :country="ffSelectedFighter[0].country"
                  size="normal"
                  rounded
                  class="pick-flag pb-0 mb-0 mx-auto text-center"
                />
                <br v-else>
              </div>
              <p class="text-center mt-1">
                Your Fantasy Fighter
              </p>
            </v-col>
            <v-col
              cols="2"
              class="col-md-2 hidden-sm-and-down"
            >
              <div class="text-center text-sm">
                Lightweight Bout<br>
                <v-btn
                  outlined
                  small
                  class="mt-2"
                  disabled
                >
                  Head-to-head <span
                  class="hidden-md-and-down"
                >&nbsp;stats</span>
                </v-btn>
              </div>
            </v-col>
            <v-col
              cols="6"
              class="col-md-5 col-sm-6"
            >
              <h4 class="text-center">
                {{ ffoponent.name }}
              </h4>
              <p class="text-xs text-center">
                "{{ ffoponent.alias }}"<br>
              </p>
              <div
                class="outter op"
                @click="powerUpCreator=true"
              >
                <div
                  class="inner"
                  :style="ffopbg"
                >
                </div>
              </div>
              <country-flag
                v-if="ffoponent"
                :country="ffoponent.country"
                size="normal"
                rounded
                class="pick-flag pb-0 mb-0 mx-auto text-center"
              />
              <p class="text-center mt-1">
                Opponent's<span
                class="hidden-sm-and-down"
              > Fantasy Fighter</span>
              </p>
            </v-col>
            <v-col
              cols="12"
              class="hidden-md-and-up col-sm-12 col-xs-12"
            >
              <div class="text-center text-sm">
                Lightweight Bout<br>
                <v-btn
                  outlined
                  small
                  class="mt-2"
                  disabled
                >
                  Head-to-head stats
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-dialog
          v-model="ffPicker"
          width="800"
        >
          <FantasyFighterPicker
            @updateFF="updateParentFF"
            @updateffPicker="ffPickerParent"
          ></FantasyFighterPicker>
        </v-dialog>
        <v-divider class="pb-2 mt-6"></v-divider>
      </div>
    </v-card>
    <v-dialog
      v-model="edit"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Fight Results</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div
              v-if="resultLoading"
              class="text-center"
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="selectedFightResult.order"
                  label="Fight Order"
                >
                </v-text-field>

                <v-text-field
                  v-model="selectedFightResult.winner"
                  label="winner"
                >
                </v-text-field>

                <v-checkbox
                  v-model="selectedFightResult.draw"
                  label="Draw"
                ></v-checkbox>
                <v-autocomplete
                  v-model="selectedFightResult.method"
                  :items="['','Decision','Decision - Split','KnockOut','Submission', 'DQ']"
                  label="Method"
                  required
                ></v-autocomplete>
                <v-autocomplete
                  v-model="selectedFightResult.bonus"
                  :items="['','Fight of the Night','Performance of the Night','Bonus']"
                  label="Bonuses"
                  required
                  @change="bonusDefault"
                ></v-autocomplete>
                <v-col
                  cols="12"
                >
                  <v-currency-field
                    v-model="selectedFightResult.bonusAmount"
                    label="Bonus Amount"
                  >
                  </v-currency-field>

                  <v-autocomplete
                    v-model="selectedFightResult.missedWeight"
                    :items="['','fighter_1','fighter_2']"
                    label="Missed Weight"
                    required
                  ></v-autocomplete>
                  <v-checkbox
                    v-model="selectedFightResult.cancelled"
                    label="Fight Canceled"
                  ></v-checkbox>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="edit = false"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click="saveResult()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer
      fixed
      inset
      app
    >
      <div
        class="picksCompleted mx-auto text-xs-center text-md-center"
        :class="{ 'desktop-picks': !isMobile}"
      >
        <div v-if="!user.isLoggedin && rosterLoaded">
          <router-link to="/login">
            <v-chip
              outlined
              large
              color="success"
              class="pick-signin"
            >
              Sign-in to save your picks
            </v-chip>
          </router-link>
        </div>
        <div v-else>
          <div v-if="ufcEvent.status == 'Upcoming'">
            <div
              v-if="!picksDone"
              class="text-center picktext"
            >
              <v-chip large>
                You've made picks in
                <v-chip class="mx-2">
                  {{ selected }}/{{ rosterLength }}
                </v-chip>
                fights.
              </v-chip>
            </div>

            <div
              v-if="picksDone"
              class="text-center"
            >
              <v-chip
                large
                color="success"
                outlined
              >
                You've completed your picks, good luck!
              </v-chip>
            </div>
          </div>
          <div v-if="ufcEvent.status == 'Live' || ufcEvent.status == 'Over'">
            <span
              class="success--text"
            >
              <span
                class="hidden-sm-and-down mr-1"
              >Event in In Progress:</span>
            </span>
            <span v-if="totalPoints">
              <span v-if="powerUpPoints">
                <v-chip class="mr-1 mb-2">{{ totalPoints }} Points +
              {{ powerUpPoints }} PowerUps</v-chip> <span
                class="hidden-sm-and-down"
              >=</span> <v-chip
                class="mx-1 mb-1"
                color="success"
                outlined
              >{{
                  grandTotalPoints
                }}  <span
                  class="hidden-sm-and-down mx-1"
                >Total </span> Points</v-chip>
              </span>
                  <v-chip v-else class="ml-2">Points: {{ totalPoints }}</v-chip>
            </span>
            <span v-if="totalSilverCoins">
              <v-chip class="ml-2"> Silver KombatCoins: {{ totalSilverCoins|toCurrencyNoDec }}
              </v-chip></span>
          </div>
          <div v-if="ufcEvent.status == 'Final'">
            <span
              class="success--text"
            >
              Event has finished, results pending...
            </span>
            <span v-if="totalPoints">
              <v-chip class="ml-2">XP Points: {{ totalPoints }}</v-chip>
            </span>
            <span v-if="totalSilverCoins">
              <v-chip class="ml-2"> Silver KombatCoins: {{ totalSilverCoins|toCurrencyNoDec }}
              </v-chip></span>
          </div>
          <div
            v-if="ufcEvent.status == 'Completed' || ufcEvent.status == 'FKCompleted'"
            class="text-center mt-2"
          >
            <span class="success--text hidden-sm-and-down">
              Event Completed.
            </span>
            <span v-if="totalPoints">
              <span class="success--text hidden-sm-and-down">You scored:</span>
              <span v-if="powerUpPoints">
                <v-chip class="mx-1 mb-2">{{ totalPoints }} Base Points</v-chip> + <v-chip
                class="mx-1 mb-2"
              >{{ powerUpPoints }} PowerUp Points</v-chip> <span
                class="hidden-sm-and-down"
              >=</span> <v-chip
                class="mx-1 mb-1"
                color="success"
                outlined
              >{{
                  grandTotalPoints
                }} Total Points</v-chip>
              </span>
              <span v-else>
                <v-chip
                  class="ml-2 mb-1"
                  color="success"
                  outlined
                >{{
                    totalPoints
                  }} Total Points</v-chip>
              </span>
            </span>
            <span v-if="totalSilverCoins"> <v-chip class="ml-2 mb-1"> Silver <span
              class="hidden-sm-and-down"
            >Kombat</span>Coins: {{
                totalSilverCoins|toCurrencyNoDec
              }}</v-chip></span>
          </div>
        </div>
      </div>
    </v-footer>
    <audio
      ref="audio"
      src='@/assets/sounds/Process Success.wav'
      id="caudio"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Quest_Game_UI_Bass_Tap_Button_Click_1_Snap.wav'
      id="caudio1"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Quest_Game_UI_Bass_Tap_Button_Click_2_Snap.wav'
      id="caudio2"
    ></audio>
    <HeadtoHead
      ref="hth"
      :headtohead="headtohead"
    ></HeadtoHead>
  </div>
</template>

<script>
import {
  mdiAlert,
  mdiCheck,
  mdiPlusCircleOutline,
  mdiInformationOutline,
  mdiCogOutline,
  mdiLoading,
} from '@mdi/js'
import Vue2Filters from 'vue2-filters'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import _ from 'lodash'
import db from '@/db/init'
import dbFunctions from '@/db'
import FighterPick from '@/views/components/FighterPick.vue'
import FigherAutoComplete from '@/views/components/FighterAutoComplete.vue'
import HeadtoHead from '@/views/components/HeadtoHead.vue'
import FantasyFighterPicker from '@/views/components/FantasyFighterPicker.vue'

export default {
  components: {
    FighterPick,
    FigherAutoComplete,
    HeadtoHead,
    FantasyFighterPicker,
  },
  filters: {
    weightClass(value) {
      if (!value) return ''
      const weightClassNames = {
        115: 'Strawweight',
        125: 'Flyweight',
        135: 'Bantamweight',
        145: 'Featherweight',
        155: 'Lightweight',
        170: 'Welterweight',
        185: 'Middleweight',
        205: 'Light Heavyweight',
        265: 'Heavyweight',
      }
      const weightName = `${weightClassNames[value]} Bout`

      return weightName
    },
    card(value) {
      if (!value) return ''
      const cardNames = {
        Main: 'Main Card',
        Prelims1: 'Prelim Card',
      }
      const cardName = cardNames[value]

      return cardName
    },
  },
  mixins: [Vue2Filters.mixin],
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiAlert,
        mdiPlusCircleOutline,
        mdiInformationOutline,
        mdiCogOutline,
        mdiLoading,
      },
    }
  },
  metaInfo() {
    return {
      title: this.ufcEvent.name,
    }
  },
  data() {
    return {
      user: {
        isLoggedin: false,
        uid: null,
      },
      headtohead: {
        show: false,
        title: '',
        data: {},
      },
      audioWrapper: {
        src: require('@/assets/sounds/Process Success.wav'),
      },
      isMobile: false,
      admin: false,
      rosterLoaded: false,
      resultLoading: false,
      updateLoader: false,
      picksDone: false,
      alreadyDone: 0,
      fromSaved: false,
      myPicks: [],
      allUsedPowerUps: {},
      totalPoints: 0,
      powerUpPoints: 0,
      grandTotalPoints: 0,
      showPoints: false,
      totalSilverCoins: 0,
      selected: 0,
      selectedPosition: 0,
      selectedOutcome: '',
      allFighters: {},
      allFightersUFCslugs: {},
      allFighterData: {},
      selectedFightResult: {},
      selectedFight: {},
      ufcEvent: {},
      fighters: {},
      edit: false,
      searchField: 'date',
      eventId: '',
      listenerLoaded: false,
      freshload: true,
      url: window.location.href,
      eventStatus: '',
      eventSlug: '',
      finalResults: false,
      defaultBonusAmount: 50000,
      statusColor: {
        Upcoming: '',
        Live: 'error',
        Final: 'success',
        Over: 'success',
        Cancelled: 'warning',
      },
      ffPicker: false,
      powerUpCreator: true,
      selectedFighter: {},
      ffSelectedFighter: {},
      ffopbg: '',
      ffoponent: {
        name: 'Gary Graham',
        alias: 'The Gorilla',
        glb: '',
        weight: '155',
        country: 'us',
      },
    }
  },
  computed: {
    roster() {
      return _.orderBy(this.ufcEvent.fights, 'order')
    },
    rosterLength() {
      let count = 0
      for (let i = 0; i < this.roster.length; i += 1) {
        if (this.roster[i]?.fight_status !== 'Cancelled') {
          count += 1
        }
      }

      return count
    },
  },
  mounted() {
    this.isMobile = this.$parent.$parent.$parent.$parent.isMobile // app.js
    setInterval(() => {
      if (this.ufcEvent.status === 'Live') {
        console.log('updated')
        this.getResults()
      }
    }, 30000)
    dbFunctions.getAllFighters().then(res => {
      res.forEach(doc => {
        const data = doc.data()
        this.allFighterData[data.slug] = data
        let c = ''
        // eslint-disable-next-line
        let name = data.name
        if (data?.country) {
          c = data.country.toString()
        }
        if (data.ufc_strike_name) {
          name = data.ufc_strike_name
        }
        this.allFightersUFCslugs[data.ufc_slug] = {
          name: data.name,
          id: data.fighter_id,
          slug: data.slug,
          ufcslug: data.ufc_slug,
          ufc_strike_name: name,
          country: c,
        }
        this.allFighters[name] = {
          name,
          id: data.fighter_id,
          slug: data.slug,
          country: c,
        }
      })
      this.getEvents()
      if (this.user.profile.dapperAddress) {
        db.collection('ufcStrikePowerUps')
          .doc(this.user.uid)
          .onSnapshot(doc => {
            this.allUsedPowerUps = doc.data()
          })
      } else {
        this.allUsedPowerUps = {}
      }
    })
    this.audioWrapper = document.getElementById('caudio')
    this.audioWrapper1 = document.getElementById('caudio1')
    this.audioWrapper2 = document.getElementById('caudio2')
  },
  methods: {
    pickListener() {
      if (!this.listenerLoaded) {
        db.collection('ufcPickEms')
          .doc(`${this.user.uid}-${this.eventSlug}`)
          .onSnapshot(doc => {
            const picks = doc.data()
            this.myPicks = picks?.data || []
            for (let i = 0; i < this.myPicks.length; i += 1) {
              if (this.ufcEvent.fights[this.myPicks[i].ufc_fight_id]) {
                const pickedkey = this.ufcEvent.fights[this.myPicks[i].ufc_fight_id]
                if (this.myPicks[i]?.picked) {
                  this.$set(pickedkey, 'picked', this.myPicks[i].picked)
                  this.$set(pickedkey, 'points', this.myPicks[i].points || 0)
                  this.$set(pickedkey, 'powerUpPoints', this.myPicks[i].powerUpPoints || 0)
                  this.$set(pickedkey, 'bonusAmount', this.myPicks[i].bonusAmount || 0)
                  this.$set(pickedkey, 'powerUps', this.myPicks[i]?.powerUps?.length || 0)
                  this.$set(pickedkey, 'confirmed', true)
                }
              }
            }
            this.checkPicks()
          })
        this.listenerLoaded = true
      }
    },
    updateParentFF(selectedFF) {
      this.ffPicker = false
      this.ffSelectedFighter = selectedFF
      this.ffbg = `background-image: url("${selectedFF[0].image}")`
      this.ffopbg = `background-image: url("${require('@/assets/images/fighters/demoff2.jpg')}")`
    },
    ffPickerParent() {
      this.ffPicker = false
    },
    fantasyFighterPick() {
      this.ffPicker = true
    },
    async checkArrayNum(value, ufcFightId) {
      // if picks not done in order add empty placeholder array
      let correct = false
      if (value >= this.myPicks.length) {
        const dif = value - this.myPicks.length
        for (let i = 0; i < dif; i += 1) {
          this.myPicks[this.myPicks.length] = {
            picked: null,
            ufc_fight_id: ufcFightId,
            powerUps: [],
          }
          correct = true
        }
      } else {
        correct = true
      }

      return correct
    },
    async selectToWin(value, ufcFightId, num, name) {
      this.freshload = false
      if (num === '2') {
        this.audioWrapper1.currentTime = 0
        this.audioWrapper1.volume = 0.1
        this.audioWrapper1.play()
      } else {
        this.audioWrapper2.currentTime = 0
        this.audioWrapper2.volume = 0.1
        this.audioWrapper2.play()
      }
      const correctArrayNum = this.checkArrayNum(value, ufcFightId)
      if (correctArrayNum) {
        if (this.ufcEvent.status === 'Upcoming') {
          if (this.user.uid) {
            this.$set(this.roster[value], 'picked', `fighter_${num}`, 'points', this.myPicks[value]?.points, 'confirmed', false)
            this.myPicks[value] = {
              picked: `fighter_${num}`,
              ufc_fight_id: ufcFightId,
              fighter_name: name,
              powerUps: this.myPicks[value]?.powerUps || [],
            }
            await dbFunctions.savePickem(this.user.uid, this.eventSlug, this.myPicks, this.ufcEvent.date, this.ufcEvent.status).then(() => {
              this.$set(this.roster[value], 'confirmed', true)
            })
          }
          if (this.myPicks) {
            this.checkPicks()
          }
          this.getSavedPicks()
        }
      } else {
        this.checkArrayNum()
      }
    },
    checkEvent() {
      if (this.ufcEvent.status === 'Final') {
        this.ufcEvent.status = 'Completed'
      } else if (this.$moment(this.ufcEvent.date) > this.$moment()) {
        this.ufcEvent.status = 'Upcoming'
      }
    },
    async processEvents() {
      if (this.ufcEvent.version === 2) {
        Object.entries(this.roster).forEach(([key, value]) => {
          this.getRosterFighter(key, value.fighter_1_slug, '1')
          this.getRosterFighter(key, value.fighter_2_slug, '2')
        })
      } else {
        for (let i = 0; i < this.roster.length; i += 1) {
          this.getRosterFighter(i, this.roster[i].fighter_1_id, '1')
          this.getRosterFighter(i, this.roster[i].fighter_2_id, '2')
        }
      }
      this.getSavedPicks()
    },
    async getResults() {
      if (this.eventStatus === 'Live') {
        console.log('event live')
        await dbFunctions.getUFCEvent(this.eventId)
          .then(eventData => {
            Object.entries(eventData.fights).forEach(([key, value]) => {
              if (value.fight_status === 'Live' || value.fight_status === 'Over' || value.fight_status === 'Final' || value.fight_status === 'Complete') {
                console.log('event updated')
                this.ufcEvent.fights[key] = value
                this.checkEvent()
                this.processEvents()
              }
            })
            if (eventData.status !== this.eventStatus) {
              this.eventStatus = eventData.status
              this.ufcEvent.status = eventData.status
            }
          })
      } else {
        console.log('event updated')
        console.log(this.eventStatus)
      }
    },
    async getEvents() {
      if (this.$store.state.user) {
        this.user = this.$store.state.user
        this.admin = this.$store.state.user.profile?.admin
      }

      if (this.$route.params.eventId || this.$route.query.eventId) {
        this.eventId = this.$route.params.eventId || this.$route.query.eventId
        this.eventSlug = this.$moment(this.eventId, 'YYYY-MM-DD').format('MMMM-DD-YYYY')
      }
      await dbFunctions.getUFCEvent(this.eventId)
        .then(eventData => {
          this.ufcEvent = eventData
          if (this.ufcEvent.version !== 2) {
            this.eventSlug = this.eventId
          }
          this.eventStatus = this.ufcEvent.status
          this.checkEvent()
          this.processEvents()
        })

      window.$crisp?.push(['set', 'session:event', [[['UFCevent', {
        status: 'joined',
        name: this.ufcEvent.name,
      }, 'brown']]]])
      if (this.user.profile.dapperAddress) {
        await dbFunctions.updateStrikeNfts(this.user.uid, this.user.profile.dapperAddress, 0, this.allFighters)
      }
    },
    async getSavedPicks() {
      if (this.user.uid) {
        await dbFunctions.getPicks(this.user.uid, this.eventSlug)
          .then(eventData => {
            this.myPicks = eventData?.data || []
            this.totalPoints = eventData?.totalPoints
            this.powerUpPoints = eventData?.totalPowerUpPoints
            this.grandTotalPoints = this.totalPoints + this.powerUpPoints

            this.totalSilverCoins += this.myPicks?.bonusAmount

            for (let i = 0; i < this.myPicks.length; i += 1) {
              if (this.ufcEvent.fights[this.myPicks[i].ufc_fight_id]) {
                const pickedkey = this.ufcEvent.fights[this.myPicks[i].ufc_fight_id]
                if (this.myPicks[i]?.picked) {
                  this.$set(pickedkey, 'picked', this.myPicks[i].picked)
                  this.$set(pickedkey, 'points', this.myPicks[i].points || 0)
                  this.$set(pickedkey, 'powerUpPoints', this.myPicks[i].powerUpPoints || 0)
                  this.$set(pickedkey, 'bonusAmount', this.myPicks[i].bonusAmount || 0)
                  this.$set(pickedkey, 'powerUps', this.myPicks[i]?.powerUps?.length || 0)
                  this.$set(pickedkey, 'confirmed', true)
                }
              }
            }
            this.checkPicks()
            this.rosterLoaded = true
            this.pickListener()
          })
      } else {
        this.rosterLoaded = true
      }
    },
    getRosterFighter(key, fighterSlug, num) {
      if (this.allFighterData[fighterSlug]) {
        this.$set(this.roster[key], [`fighter_${num}_data`], this.allFighterData[fighterSlug])
      } else if (this.allFighterData[this.allFightersUFCslugs[fighterSlug]?.slug]) {
        this.$set(this.roster[key], [`fighter_${num}_data`], this.allFighterData[this.allFightersUFCslugs[fighterSlug].slug])
      }
    },
    checkPicks() {
      let totalPicked = 0
      for (let i = 0; i < this.roster.length; i += 1) {
        if (this.roster[i].picked && this.roster[i]?.fight_status !== 'Cancelled') {
          totalPicked += 1
        }
      }
      this.selected = totalPicked
      let pickStatus = 'incomplete'
      if (this.selected >= this.rosterLength) {
        this.picksDone = true
        pickStatus = 'complete'
        if (this.ufcEvent.status.toLowerCase() === 'upcoming' && this.rosterLoaded) {
          if (this.alreadyDone === 0 && !this.freshload) {
            this.audioWrapper.currentTime = 0
            this.audioWrapper.volume = 0.1
            this.audioWrapper.play()
            this.alreadyDone += 1
          }
        }
      } else {
        this.picksDone = false
      }
      window.$crisp?.push(['set', 'session:event', [[['UFCevent', {
        status: pickStatus,
        name: this.ufcEvent.name,
      }, 'green']]]])
    },
    editResultModal(item, value, position) {
      this.edit = true
      this.selectedFight = item
      this.selectedIndex = value
      this.selectedPosition = position
      this.selectedFightResult = this.roster[this.selectedIndex].fight_results
    },
    saveResult() {
      const result = this.roster[this.selectedIndex]
      result.points = 0
      if (this.selectedFightResult.cancelled) {
        this.roster[this.selectedIndex].cancelled = true
      } else {
        this.roster[this.selectedIndex].cancelled = false
      }
      this.$set(result, ['fight_results'], this.selectedFightResult)
      dbFunctions.updateUFCEvent(this.eventId, {
        fights: this.roster,
        status: this.ufcEvent.status,
        setfighterPending: true,
      }).then(() => {
        this.edit = false
        this.selectedFightResult = {
          draw: false,
          winner: '',
          method: '',
          bonus: '',
          missedWeight: false,
          bonusAmount: 0,
          cancelled: false,
        }
      })
      if (this.ufcEvent.status === 'Live' && !this.ufcEvent.setfighterPending) {
        dbFunctions.setUFCEventStatusBlockTrading(this.roster)
      }
    },
    async addUpdateFighter(fighterSlug) {
      console.log('update fighter')
      console.log(fighterSlug)
      this.addLoading = true
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000, // whatever time you want
      })

      return api.get(`fightername/?name=${fighterSlug}`).then(response => {
        this.addLoading = false
        const fighterData = response.data.fighter[0]
        dbFunctions.getBaseFighterSlug(fighterSlug)
          .then(fdata => {
            console.log(fdata.token_template_id)
            if (fdata.token_template_id) {
              fighterData.token_template_id = fdata.token_template_id
              console.log('template sync')
              console.log(fighterData)
              const startSyncTemplate = firebase.functions().httpsCallable('syncTemplates')
              startSyncTemplate(fighterData)
                .then(() => {
                  dbFunctions.syncFighterBaseMintedTemplate(fighterData)
                    .then(() => {
                      console.log('sync complete')
                    })
                })
                .catch(err => {
                  console.log(err)
                })
            } else {
              console.log('no template id')
            }
          })
      })
    },
    async updateResults(updateFighters) {
      this.updateLoader = true
      console.log(this.ufcEvent.eventId)
      console.log('Update UFC results')
      const api = axios.create({
        baseURL: this.$baseURL,
        timeout: 10 * 60 * 1000,
      })
      let url = `results?eventid=${this.ufcEvent.eventId}`
      if (updateFighters) {
        url = `${url}&force-fighter-update=true`
      }
      api.get(url).then(() => {
        this.getResults()
        this.updateLoader = false
      }).catch(err => {
        console.log(err)
        this.updateLoader = false
      })
    },
    assignPicks() {
      this.updateLoader = true
    },
    bonusDefault(e) {
      if (e) {
        this.selectedFightResult.bonusAmount = this.defaultBonusAmount
      }
    },
    openHeadtoHead(item) {
      this.$refs.hth.updateSeries(item)
      this.headtohead.title = `${item.fighter_1} vs ${item.fighter_2}`
      this.headtohead.show = true
      setTimeout(() => window.dispatchEvent(new Event('resize')), 10)
    },
    resetPicks() {
      this.freshload = true
      this.alreadyDone = 0
      for (let i = 0; i < this.myPicks.length; i += 1) {
        this.myPicks[i] = {}
      }
      dbFunctions.savePickem(this.user.uid, this.eventSlug, this.myPicks, this.ufcEvent.date, this.ufcEvent.status).then(() => {
        dbFunctions.resetPowerUps(this.user.uid)
        this.getEvents()
      })
    },
  },
}
</script>

<style lang="scss" scoped>

.dfs-settings {
  right: 5px;
  position: absolute;
}

.eventCancelled .v-icon {
  display: none;
}

.eventCancelled .theme--dark.f-card, .eventCancelled .f-card, .eventCancelled .theme--dark.f-card.chosen {
  border: none !important;
}

.mobile .theme--dark.v-card.pick-main {
  background-color: #222 !important;
}

.picksCompleted .v-chip.v-size--large {
  height: 45px;
}

.v-card.chosen {
  border: 3px solid #1abadb;
  margin: -6px 0 -4px 0;
}

.v-icon.spinner {
  background: transparent !important;
  animation: spin 0.7s linear infinite;
}

.v-icon.f1-check.theme--dark {
  color: #fff;
  height: 25px;
  left: 0;
  position: absolute;
  background: #1abadb;
  border-radius: 0 0 100px;
  padding: 7px 7px 12px 2px;
  margin: 0px 0 0 -1px;

}

.v-icon.f2-check.theme--dark {
  color: #fff;
  height: 25px;
  right: 0;
  position: absolute;
  background: #1abadb;
  border-radius: 0 100px 0;
  padding: 5px 3px 10px 6px;
  margin: 0px -3px 0 0;
}

.f-card {
  border: 2px solid #fff;
  margin: -4px 0;
}

.pick-signin {
  cursor: pointer;
}

.can--catch .add-icon {
  color: #1abadb;
  transform: rotate(45deg);
}

.add-icon {
  left: 32px;
  top: 32px;
}

.outter:before,
.outter:after,
.inner:before,
.inner:after {
  background: #fff;
}

.can--catch.outter:before,
.can--catch.outter:after,
.can--catch .inner:before,
.can--catch .inner:after {
  background: #1abadb;
}

.inner {
  border-color: #fff;
}

.can--catch .inner {
  border-color: #1abadb;
}

.can--catch.outter:after, .can--catch.outter:before {
  background: #1abadb !important;
}

.outter {
  position: relative;
  margin: auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
  z-index: 0;
}

.outter:before,
.outter:after {
  content: "";
  position: absolute;
  left: 0;
  top: 70%;
  width: 3px;
  height: 42px;
  margin-top: -41px;
  z-index: 1;
}

.outter:after {
  left: auto;
  right: 0;
  margin-top: -41px;
  height: 42px;
}

.inner {
  position: absolute;
  top: 0px;
  width: 100px;
  height: 100px;
  background: #111;
  border-width: 3px;
  border-style: solid;
  -webkit-transform: rotate(45deg);
  z-index: -1;
}

.inner:before,
.inner:after {
  -webkit-transform: rotate(45deg);
  content: "";
  position: absolute;
  width: 3px;
  height: 40px;
  margin-top: -35px;
  z-index: 1;
  right: auto;
  left: 11px;
  bottom: auto;
  top: 28px;
}

.inner:after {
  height: 41px;
  right: 11px;
  left: auto;
  bottom: -8px;
  top: auto;
}

.ff.outter {
  transform: rotate(-45deg)
}

.ff .inner {
  background-size: cover !important;
}

.op.outter {
  transform: rotate(-45deg)
}

.op .inner {
  background-size: cover !important;
  background: url('~@/assets/images/fighters/demoff2.jpg')
}

.ffleague .pick-flag.normal-flag {
  margin: auto;
  display: block;
  position: relative;
  margin-top: -20px;
  transform: scale(0.4);
}

.grey-disable {
  filter: grayscale(1);
  opacity: 0.7;
  filter: blur(1px);
}

.eventCancelled .chosen {
  border-color: #88888830 !important;
}

.eventCancelled .f1-check, .eventCancelled .f2-check {
  background: #88888830 !important;
}

</style>
