<template>
  <div class="text-center">
    <v-dialog
      v-model="headtohead.show"
      width="600"
    >
      <v-card v-if="fdata">
        <v-icon
          class="closed"
          @click="closeDialog()"
        >
          {{ icons.mdiClose }}
        </v-icon>
        <v-card-title class="mb-1 pb-1 mt-1 pt-1"></v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              col="6"
              class="col-6 text-center"
            >
              <h3 class="text-center mx-auto mb-2 text-truncate">
                {{ fdata.fighter_1 }}
              </h3>
              <canvas
                id="fighter_1"
              />
              <div
                class="imgbg fighter_1"
                :class="{ borderwin: (fdata.fight_results && fdata.fight_results.winner == 'fighter_1' && !fdata.fight_results.draw && !fdata.fight_results.cancelled), borderloss: (fdata.fight_results && fdata.fight_results.winner != 'fighter_1' && !fdata.fight_results.draw && !fdata.fight_results.cancelled) }"
              >
                <span
                  v-if="fdata.fight_results && fdata.fight_results.winner == 'fighter_1'"
                  class="winner"
                >Winner</span>
                <v-img
                  contain
                  class="mirror fbody"
                  :src="f1image"
                  @error="imageError('f1')"
                ></v-img>
              </div>
              <div class="streaklist f1streak">
                <v-badge
                  class="text-capitalize streak next"
                  inline
                  tile
                  content="+"
                >
                </v-badge>
                <span v-if="f1streak.length>0">
                  <v-badge
                    v-for="(item, idx) in f1streak"
                    :key="idx"
                    class="text-capitalize streak"
                    :color="wl[item]"
                    inline
                    tile
                    :content="item"
                  >
                  </v-badge>
                </span>
                <span v-else>
                  <v-badge
                    class="text-capitalize streak"
                    inline
                    tile
                    content="-"
                  >
                  </v-badge>
                </span>
              </div>
            </v-col>
            <v-col
              col="6"
              class="col-6 text-center"
            >
              <h3 class="text-center mx-auto mb-2 text-truncate">
                {{ fdata.fighter_2 }}
              </h3>
              <canvas
                id="fighter_2"
              />
              <div
                class="imgbg fighter_2"
                :class="{ borderwin: (fdata.fight_results && fdata.fight_results.winner == 'fighter_2' && !fdata.fight_results.draw && !fdata.fight_results.cancelled), borderloss: (fdata.fight_results && fdata.fight_results.winner != 'fighter_2' && !fdata.fight_results.draw && !fdata.fight_results.cancelled) }"
              >
                <span
                  v-if="fdata.fight_results && fdata.fight_results.winner == 'fighter_2'"
                  class="winner"
                >Winner</span>
                <v-img
                  class="fbody"
                  contain
                  :src="f2image"
                  @error="imageError('f2')"
                ></v-img>
              </div>
              <div class="streaklist f2streak">
                <v-badge
                  class="text-capitalize streak next"
                  inline
                  tile
                  content="+"
                >
                </v-badge>
                <span v-if="f2streak.length>0">
                  <v-badge
                    v-for="(item, idx) in f2streak"
                    :key="idx"
                    class="text-capitalize streak"
                    :color="wl[item]"
                    inline
                    tile
                    :content="item"
                  >
                  </v-badge>
                </span>
                <span v-else>
                  <v-badge
                    class="text-capitalize streak"
                    inline
                    tile
                    content="-"
                  >
                  </v-badge>
                </span>
              </div>
            </v-col>
          </v-row>

          <v-row class="hthstats text-center mt-1">
            <v-col>
              <v-row>
                <v-col
                  col="5"
                  class="col-5 ma-0 pa-0"
                >
                  <v-chip
                    v-if=" fdata.fighter_1_data.rank"
                    class="rank-chip"
                    color="grey"
                    :class="{ 'silver': fdata.fighter_1_data.rank <= 16, 'gold': fdata.fighter_1_data.rank<= 11 }"
                    small
                  >
                    <div v-if=" fdata.fighter_1_data.rank==1">
                      <v-icon small>
                        {{ icons.mdiCrownOutline }}
                      </v-icon>
                    </div>
                    <div v-else>
                      {{ fdata.fighter_1_data.rank - 1 }}
                    </div>
                  </v-chip>
                  <div v-else>
                    -
                  </div>
                  <v-divider></v-divider>
                  {{ fdata.fighter_1_data.win }}-{{
                    fdata.fighter_1_data.loss
                  }}-{{ fdata.fighter_1_data.draw }}
                  <v-divider></v-divider>
                  <div v-if="fdata.fighter_1_data.country">
                    <country-flag
                      :country="fdata.fighter_1_data.country"
                      rounded
                      size="normal"
                    />
                  </div>
                  <v-divider></v-divider>
                  {{ fdata.fighter_1_data.age }}
                  {{ fdata.fighter_1_data.height }}
                  <v-divider></v-divider>
                  {{ fdata.fighter_1_data.weight }}
                  <v-divider></v-divider>
                  {{ fdata.fighter_1_data.reach }}
                  <v-divider></v-divider>
                </v-col>
                <v-col
                  col="2"
                  class="col-2 ma-0 pa-0"
                >
                  <p class="mb-0">
                    Rank
                  </p>
                  <p class="mt-3">
                    Record
                  </p>
                  <p class="mt-2">
                    Country
                  </p>
                  <p class="">
                    Height
                  </p>
                  <p class="">
                    Weight
                  </p>
                  <p class="">
                    Reach
                  </p>
                </v-col>
                <v-col
                  col="5"
                  class="col-5 ma-0 pa-0"
                >
                  <v-chip
                    v-if=" fdata.fighter_2_data.rank"
                    class="rank-chip"
                    color="grey"
                    :class="{ 'silver': fdata.fighter_2_data.rank <= 16, 'gold': fdata.fighter_2_data.rank<= 11 }"
                    small
                  >
                    <div v-if=" fdata.fighter_2_data.rank==1">
                      <v-icon small>
                        {{ icons.mdiCrownOutline }}
                      </v-icon>
                    </div>
                    <div v-else>
                      {{ fdata.fighter_2_data.rank - 1 }}
                    </div>
                  </v-chip>
                  <div v-else>
                    -
                  </div>
                  <v-divider></v-divider>
                  {{ fdata.fighter_2_data.win }}-{{
                    fdata.fighter_2_data.loss
                  }}-{{ fdata.fighter_2_data.draw }}

                  <v-divider></v-divider>
                  <div v-if="fdata.fighter_2_data.country">
                    <country-flag
                      :country="fdata.fighter_2_data.country"
                      rounded
                      size="normal"
                    />
                  </div>
                  <v-divider></v-divider>
                  {{ fdata.fighter_2_data.height }}
                  <v-divider></v-divider>
                  {{ fdata.fighter_2_data.weight }}
                  <v-divider></v-divider>
                  {{ fdata.fighter_2_data.reach }}
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-tabs color="success">
            <v-tab
              v-if="enableFightSummary&& fdata.fight_results && fdata.fight_results.winner_name">
              Fight Summary
            </v-tab>
            <v-tab-item
              v-if="enableFightSummary && fdata.fight_results && fdata.fight_results.winner_name"
              class="text-xs"
            >
              <h3
                v-if="fdata.fight_results.winner"
                class="mt-3 text-center"
              >
                {{ fdata.fight_results.winner_name }} won by
                {{ fdata.fight_results.method }}.
              </h3>
              <p class="text-center mb-1 mt-1">
                Round {{ fdata.fight_results.fight_duration_lastrnd }}
                <span v-if="fdata.fight_results.fight_duration_lastrnd_time"> (time:{{
                    fdata.fight_results.fight_duration_lastrnd_time
                  }})</span>
                <span v-if="fdata.fight_results.method_detail"> via {{
                    fdata.fight_results.method_detail
                  }}</span>
              </p>
              <v-divider class="mt-2"></v-divider>
              <v-row class="hthstats text-center mt-2">
                <v-col>
                  <v-row>
                    <v-col
                      col="5"
                      class="col-5 ma-0 pa-0"
                    >
                      {{ fdata.fight_results.kd[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.total_str_land[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.head_land[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.body_land[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.leg_land[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.td_land[0] }}/{{ fdata.fight_results.td_att[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.ctrl[0] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.n_sub[0] }}
                    </v-col>
                    <v-col
                      col="2"
                      class="col-2 ma-0 pa-0"
                    >
                      <p class="mb-3">
                        Knock-downs
                      </p>
                      <p class="mb-3">
                        Strikes landed
                      </p>
                      <p class="mt-2">
                        Head Shots
                      </p>
                      <p class="mt-2">
                        Body Shots
                      </p>
                      <p class="">
                        Leg Shots
                      </p>
                      <p class="">
                        Take-downs
                      </p>
                      <p class="">
                        Control Time
                      </p>
                      <p class="">
                        Sub. Attempts
                      </p>
                    </v-col>
                    <v-col
                      col="5"
                      class="col-5 ma-0 pa-0"
                    >
                      {{ fdata.fight_results.kd[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.total_str_land[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.head_land[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.body_land[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.leg_land[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.td_land[1] }}/{{ fdata.fight_results.td_att[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.ctrl[1] }}
                      <v-divider></v-divider>
                      {{ fdata.fight_results.n_sub[1] }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab>Fighter Stats</v-tab>
            <v-tab-item>
              <div id="chart-wrapper">
                <div id="chart">
                  <VueApexCharts
                    ref="radar"
                    type="radar"
                    :options="chartOptions"
                    :series="series"
                  >
                  </VueApexCharts>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <div class="text-center mb-4">
          <v-btn
            outlined
            @click="closeDialog()"
          >
            Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import {
  mdiCrownOutline,
  mdiTimerSand,
  mdiClose,
} from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    headtohead: {
      show: Boolean,
      title: String,
      link: String,
      chatButton: Boolean,
    },
  },
  data() {
    return {
      icons: {
        mdiCrownOutline,
        mdiTimerSand,
        mdiClose,
      },
      enableFightSummary: false,
      fdata: '',
      isMobile: false,
      f1streak: [],
      f2streak: [],
      defaultImage: require('@/assets/images/fighters/default-profile.png'),
      f1image: this.defaultImage,
      f2image: this.defaultImage,
      winner: '',
      wl: {w: 'success', l: 'error'},
      series: [{
        name: '',
        data: [],
      }, {
        name: '',
        data: [],
      }],
      chartOptions: {
        chart: {
          height: 100,
          type: 'radar',
          toolbar: {
            show: false,
          },
        },
        legend: {
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
        },
        fill: {
          opacity: 0.1,
        },
        markers: {
          size: 0,
        },
        plotOptions: {
          radar: {
            size: 60,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff'],
              },
            },
          },
        },
        yaxis: {
          show: false,
        },
        xaxis: {
          categories: ['Striking attack', 'Striking defence', 'Takedown attack', 'Takedown defence', 'Submissions'],
        },
      },
    }
  },
  mounted() {
    this.isMobile = this.$parent.$parent.$parent.$parent.$parent.isMobile // app.js
    if (!this.isMobile) {
      this.chartOptions.height = 100
      this.chartOptions.plotOptions.radar.size = 100
    }
  },
  methods: {
    updateSeries(data) {
      this.fdata = data
      if (this.fdata.fight_results?.winner) {
        this.winner = this.fdata.fight_results.winner
      }
      if (this.fdata.fighter_1_data?.streak) {
        this.f1streak = this.streak(this.fdata.fighter_1_data.streak)
      }
      if (this.fdata.fighter_2_data?.streak) {
        this.f2streak = this.streak(this.fdata.fighter_2_data.streak)
      }
      this.f1image = `${this.fighterImagesURL}fighters/images/body-${this.fdata.fighter_1_data.slug}_248x380.png`
      this.f2image = `${this.fighterImagesURL}fighters/images/body-${this.fdata.fighter_2_data.slug}_248x380.png`

      this.series = [{
        name: data.fighter_1,
        data: [
          data.fighter_1_data.sig_str_land_pM,
          data.fighter_1_data.sig_str_def_pct * 10,
          data.fighter_1_data.td_land_pct * 10,
          data.fighter_1_data.td_def_pct * 10,
          data.fighter_1_data.sub_avg * 2,
        ],
      }, {
        name: data.fighter_2,
        data: [
          data.fighter_2_data.sig_str_land_pM,
          data.fighter_2_data.sig_str_def_pct * 10,
          data.fighter_2_data.td_land_pct * 10,
          data.fighter_2_data.td_def_pct * 10,
          data.fighter_2_data.sub_avg * 2,
        ],
      }]
      const confetti = this.$confetti
      if (this.fdata.fight_results?.winner) {
        setTimeout(() => {
          confetti.start(
            {
              defaultSize: 2,
              defaultDropRate: 2,
              canvasId: this.winner,
            },
          )
        }, 0)
      } else {
        confetti.stop()
      }
    },
    closeDialog() {
      this.$confetti.stop()
      this.fdata = ''
      this.f1streak = ''
      this.f2streak = ''
      this.series = []
      this.slug = ''
      this.headtohead.show = false
    },
    streak(text) {
      let streaks = 9
      if (window.innerWidth > 1200) {
        streaks = 19
      }
      const limit = text.substring(0, streaks).slice()

      return limit.split(',')
    },
    imageError(fighter) {
      if (fighter === 'f1') {
        this.f1image = this.defaultImage
      }
      if (fighter === 'f2') {
        this.f2image = this.defaultImage
      }
    },
  },
}
</script>

<style>

.mobile span.v-badge__badge.success {
  font-size: 10px;
  margin: -1px;
  padding: 6px;
}

.mobile .row.hthstats.text-center.mt-1 {
  font-size: 11px;
}

.imgbg {
  background: url('/img/purplecomb.webp') no-repeat;
  background-size: cover;
  padding-top: 12px;
  transition: all 1000ms;
}

.v-image.fbody {
  height: 110px;
  z-index: 0;
}

.v-image.mirror {
  transform: scaleX(-1);
}

.v-badge.streak > span {
  margin: 5px 0 !important;
  z-index: 2;
  position: relative;
  opacity: 0.75;
}

.streaklist {
  background: #28243d;
  height: 31px;
  border-radius: 6px;
  z-index: 1;
  position: relative;
}

.f1streak .streak:last-child span, .f2streak .streak:last-child span {
  border-radius: 0px 5px 5px 0;
}

.streak.next.v-badge span {
  border-radius: 5px 0 0 5px;

}

.hthstats hr.v-divider {
  margin: 6px 0px 7px 0px;
}

.hthstats strong {
  line-height: 21.5px;
}

.hthstats .normal-flag {
  margin: -14px 0;
}

.apexcharts-canvas .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 0.8rem !important;
}

@media only screen and (min-width: 600px) {

  div#chart-wrapper {
    width: 108%;
  }

  div#chart {
    margin-top: -40px;
    margin-bottom: -70px;
  }

  .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
    bottom: 70px !important;
  }
}

@media only screen and (max-width: 600px) {
  div#chart {
    margin-top: 20px;
    margin-bottom: -20px;
  }

  div#chart-wrapper {
    width: 110%;
    margin-left: -10px;
  }

  .apexcharts-legend.apexcharts-align-center.apx-legend-position-bottom {
    bottom: 10px !important;
  }

}

.imgbg.borderloss {
  border: 2px solid #ff4c51;
  border-radius: 8px;
  z-index: 1;
}

.imgbg.borderwin {
  border: 2px solid #56ca00;
  border-radius: 8px;
  z-index: 1;
}

canvas#fighter_1 {
  position: absolute;
  left: 24px;
  width: 45%;
  padding-top: 2px;
  height: 123px;
}

canvas#fighter_2 {
  position: absolute;
  right: 24px;
  width: 45%;
  padding-top: 2px;
  height: 123px;
}

.imgbg.fighter_1 .v-image__image {
  opacity: 0;
  transition: linear 500ms;
  animation-delay: 0s;
  transform: scaleX(-1);
  transform: scale(0.92) skew(0deg, 0deg);
  top: 4px;
  filter: blur(0.6px);
  animation-name: bgzoom;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.imgbg.fighter_2 .v-image__image {
  opacity: 0;
  transition: linear 500ms;
  animation-delay: 0s;
  animation-name: bgzoom;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
}

@keyframes bgzoom {
  from {
    opacity: 1;
    transform: scale(0.92) skew(2deg, 0deg);
    top: 4px;
    filter: blur(0.5px);
    backdrop-filter: blur(0px);
  }
  to {
    opacity: 1;
    transform: scale(1) skew(0deg, 2deg);
    top: 0px;
    filter: blur(0px);
    backdrop-filter: blur(1.5px);
  }
}

.borderwin .v-image.fbody {
  margin-top: -21px;
}

.fighter_1 .winner {
  position: relative;
  top: -22px;
  background: #56ca00;
  border-radius: 5px;
  padding: 0 4px 0 4px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
}

.fighter_2 .winner {
  position: relative;
  top: -22px;
  background: #56ca00;
  border-radius: 5px;
  padding: 0 4px 0 4px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
}

button.v-icon.closed {
  position: absolute;
  right: 5px;
  top: 2px;
}

</style>
