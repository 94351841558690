<template>
  <div>
    <div
      ref="myDraggable"
      class="draggable"
      :class="`index-${index}`"
      @mouseover="init"
    >
      <div

        class="outter"
        :class="{'drop--me':data.status === 'used'}"
      >
        <svg viewBox="0 0 75 75" width="109px">
          <path d="m5,22 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z" stroke="#ffffffb0"
                stroke-width="2" fill="transparent"/>
        </svg>
        <div
          class="inner"
        >
          <span class="ufc-mov">{{ data.mov }}</span><br>
          <div class="ufc-name mb-0 pb-0">
            <div
              v-resize-text="{maxFontSize: '12px', minFontSize: '10px', delay: 200}"
              class="ufc-name-text"
            >
              {{ data.name|namefirst }}<br>
              <div class="text-truncate">
                {{ data.name|nameothers }}
              </div>
            </div>
          </div>
          <country-flag
            v-if="data.country"
            :country="data.country"
            size="normal"
            rounded
            class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'
import {mdiPlay, mdiCloseCircleOutline} from '@mdi/js'

export default {
  name: 'Draggable',
  filters: {
    namefirst(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')

      return allnames[0]
    },
    nameothers(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')
      const lastnames = []
      for (let i = 0; i < allnames.length; i += 1) {
        if (i > 0) {
          lastnames.push(allnames[i])
        }
      }

      return lastnames.join(' ')
    },
  },
  props: {
    data: Object,
    index: Number,
    staticBase: String,
    fighterId: String,
    dragNdrop: Boolean,
  },
  data() {
    return {
      dragInit: false,
      icons: {
        mdiPlay,
        mdiCloseCircleOutline,
      },
      name: '',
      tier: '',
      bg: '',
    }
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.tier = this.data.tier
    this.base = '/img'
    this.bg = `background-image: url('${this.base}/${this.tier.toLowerCase()}.png')`

    // this.init()
  },
  methods: {

    init() {
      if (!this.dragInit && this.dragNdrop) {
        this.initInteract()
      } else if (this.dragInit && !this.dragNdrop) {
        this.disableInteract()
      }
    },
    disableInteract() {
      interact('.draggable').unset()
    },
    initInteract() {
      this.dragInit = true
      const fighterId = `fighter-${this.fighterId}`
      let startPos = null
      interact('.draggable').draggable({
        snap: {
          targets: [startPos],
          range: Infinity,
          relativePoints: [{x: 0.95, y: 0.5}],
          endOnly: true,
        },
        listeners: {
          start(event) {
            console.log(event.type, event.target)
            const rect = interact.getElementRect(event.target)
            const {target} = event
            let x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
            let y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

            if (!target.getAttribute('data-dropped')) {
              // keep the dragged position in the data-x/data-y attributes
              x = event.dx
              y = event.dy
            }

            // translate the element
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            // eslint-disable-next-line no-multi-assign
            target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`
            target.classList.remove('drop--me')

            // record center point when starting the very first a drag
            startPos = {
              x: rect.left + rect.width / 2,
              y: rect.top + rect.height / 2,
            }

            event.interactable.draggable({
              snap: {
                targets: [startPos],
              },
            })
          },
          move(event) {
            const {target} = event

            // keep the dragged position in the data-x/data-y attributes
            const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
            const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

            // translate the element
            // eslint-disable-next-line no-multi-assign
            target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`

            // update the posiion attributes
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
            target.classList.add('getting--dragged')
            target.classList.add('getting--dragged')
          },
          end(event) {
            console.log('end')
            const {target} = event
            if (event.dropzone === undefined) {
              target.classList.remove('drop--me')
              // eslint-disable-next-line no-multi-assign
              target.style.webkitTransform = target.style.transform = `translate(${0}px, ${0}px)`
              event.interactable.draggable({
                snap: {
                  targets: [startPos],
                },
              })
            } else {
              const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
              target.classList.add(`dropslot-${event.relatedTarget.dataset.dropslot}`)
              // eslint-disable-next-line no-multi-assign
              target.style.webkitTransform = target.style.transform = `translateY(${y}px)`
              console.log(fighterId)
              document.getElementById(fighterId).appendChild(target)
              // eslint-disable-next-line no-multi-assign
              target.setAttribute('data-dropped', true)
            }
          },
        },
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.can--catch .add-icon {
  color: #1abadb;
}

.can--catch .add-icon {
  color: #1abadb;
}

.add-icon {
  left: 32px;
  top: 32px;
}

.ff.outter {
  transform: rotate(-45deg)
}

.ff .inner {
  background-size: cover !important;
}

.op.outter {
  transform: rotate(-45deg)
}

.drop--me svg {
  fill: #56ca00 !important;
}

.outter {
  position: relative;
  top: 13px;
  width: 105px;
  height: 105px;
  overflow: hidden;
  z-index: 0;
}

.inner {
  position: absolute;
  top: 0.5px;
  width: 100px;
  height: 100px;
  background: #111;
  background-size: contain;
  z-index: -1;
  left: 4px;
}

#octogon .pick-flag {
  position: relative;
  left: 24px;
  bottom: 9px;
}

.mini-preview {
  margin: auto;
  display: block;
  position: relative;
  left: 45px;
  top: -30px;
  opacity: 0.8;
  width: 5px;
}

.mini-preview:hover {
  opacity: 1;
}

span.ufc-mov {
  display: block;
  margin: auto;
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 10px;
  top: 16px;
  margin-bottom: -15px;
}

div.ufc-name {
  display: block;
  z-index: 2;
  position: relative;
  background: #222;
  width: 70px;
  height: 31px;
  padding: 0px 2px 0px 2px;
  border-radius: 2px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  margin: auto;
  top: 10px;
}

div.ufc-name-text {
  width: 70px !important;
  display: block;
  margin: auto;
  text-align: center;
  color: #fff;
  font-weight: 400;
  line-height: 13px;
  padding: 1 pngpx;
}
</style>
