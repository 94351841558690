<template>
  <div id="powerups-comp">
    <v-card class="pu-card overflow-hidden">
      <v-icon
        class="ma-4 close"
        large
        @click="childClosePowerUp"
      >
        {{ icons.mdiCloseCircleOutline }}
      </v-icon>
      <v-card-title>
        Add PowerUps: <span class="hidden-md-and-down ml-1 mr-1">({{
          count
        }}/4):</span><br class="hidden-md-and-up"> {{ fighter.name }}
      </v-card-title>
      <v-card-text :class="{savingstate:saving}">
        <div class="pu-chips mt-2 pt-2">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="search"
                clearable
                color="success"
                label="Search"
                class="pu-search ml-5"
                :prepend-inner-icon="icons.mdiMagnify"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-country-select
                v-model="country"
                clearable
                :country-name="false"
                color="success"
                placeholder="Country"
                class="pu-country"
                short-code-dropdown="true"
              />
            </v-col>
          </v-row>
          <v-chip
            v-ripple
            class="ml-0 mr-1 mb-1"
            :class="{active:powerups.owner, ownerf:filter.owner}"
            @click="fOwner(fighter.name)"
          >
            Fighter: <span class="pu-num">{{
              powerups.owner
            }}</span>
          </v-chip>
          <v-chip
            id="tierChip"
            v-ripple
            class="mx-1 mb-1 tier-chip"
            :class="({active:powerups.tier, tierf:filter.tier}, tiers)"
            @click="toggleTselect = !toggleTselect"
          >
            Tier: <span class="pu-num">{{
              powerups.tier
            }}</span>
            <v-select
              v-model="tiers"
              :menu-props="{value: toggleTselect}"
              :items="tierList"
              color="success"
              class="tier-select"
              label="Tiers"
              @change="toggleTselect = !toggleTselect"
            ></v-select>
          </v-chip>
          <v-chip
            v-ripple
            class="mx-1 mb-1"
            :class="{active: powerups.ko, kof:filter.ko}"
            @click="fMovs('KO')"
          >
            KO/TKO: <span class="pu-num">{{
              powerups.ko
            }}</span>
          </v-chip>
          <v-chip
            v-ripple
            class="mx-1 mb-1"
            :class="{active:powerups.sub, subf:filter.sub}"
            @click="fMovs('SUB')"
          >
            Sub<span class="hidden-md-and-down">mission</span>: <span class="pu-num">{{
              powerups.sub
            }}</span>
          </v-chip>
          <v-chip
            v-ripple
            class="mx-1 mb-1"
            :class="{active:powerups.dec, decf:filter.dec}"
            @click="fMovs('DEC')"
          >
            Dec<span class="hidden-md-and-down">ision</span>: <span class="pu-num">{{
              powerups.dec
            }}</span>
          </v-chip>
          <v-chip
            v-ripple
            class="mx-1 mb-1"
            :class="{active:powerups.country, countryf:filter.country}"
            @click="fCountry(fighter.country)"
          >
            Country: <span class="pu-num">{{
              powerups.country
            }}</span>
          </v-chip>
          <v-btn
            x-small
            class="mt-1 ml-1 mb-2 hidden-md-and-down"
            @click="resetFilter"
          >
            Reset
            Filters
          </v-btn>
        </div>
        <div class="powerup-inst mt-6 mb-1">
          <div
            v-if="picksLocked"
            id="pulocked"
          >
            <p>Picks Locked</p>
          </div>
          <div v-else-if="!puLoading && !fullStack" class="text-center">
            <p> Click to enable <span class="hidden-sm-and-down">upto 4</span> PowerUps.</p>
          </div>
          <div
            v-else-if="fullStack"
            class="text-center"
          >
            <p>
              <v-icon color="success" class="ma-0 pa-0">
                {{ icons.mdiCheckCircleOutline }}
              </v-icon>
              PowerUp Stack Complete!
            </p>
          </div>
          <div
            v-if="puLoading"
            class="pu-wait text-center"
          >
            <v-icon class="pu-spinner">
              {{ icons.mdiLoading }}
            </v-icon>
            Loading PowerUps...
          </div>
        </div>
        <div
          v-if="!puLoading"
          class="powerUpList"
        >
          <v-slide-group
            :id="`fighter-${fighter.id}`"
            :key="`fighter-${fighter.id}`"
            v-model="filteredMyNfts"
            show-arrows
            :class="slideClass"
            :multiple="multipleSelect"
            :prev-icon="icons.mdiArrowLeftBoldCircleOutline"
            :next-icon="icons.mdiArrowRightBoldCircleOutline"
          >
            <v-slide-item
              v-for="(element, idx) in filteredMyNfts"
              :key="idx"
            >
              <div
                :id="`puId-${idx}`"
                class="drag-wrapper"
                @click="selectPU($event, idx, element, false)"
              >
                <div :class="{selectedPU: element.status==='used'}">
                  <Draggable
                    ref="drag"
                    :key="idx"
                    :data="element"
                    :index="idx"
                    :class="`tier-${element.tier.toLowerCase()}`"
                    :static-base="fighterImagesURL"
                    :fighter-id="fighter.id"
                    :drag-ndrop="dragNdrop"
                  />
                  <p
                    v-if="element.status==='used' && !saving"
                    :id="`used-${element.id}`"
                    class="status text-xs"
                  >
                    Already
                    in use
                  </p>
                </div>
              </div>
            </v-slide-item>
            <div
              v-if="!filteredMyNfts.length"
              class="no-pu-match"
            >
              No Matching PowerUps.<br>
              <v-btn
                small
                class="mt-2"
                @click="resetAll"
              >
                Reload
              </v-btn>
            </div>
          </v-slide-group>
        </div>
        <div
          v-if="saving"
          class="saving"
        >
          <v-icon class="pu-spinner mr-2">
            {{ icons.mdiLoading }}
          </v-icon>
          Saving..
        </div>
        <div class="power-up-stack">
          <div
            class="focus-card"
            :class="{fcpad: fighter}"
          >
            <v-avatar
              size="100%"
              round
              class="pick-avatar mx-auto pb-0 mb-0"
              :class="{'borderwin':fullStack}"
            >
              <v-img
                lazy-src="https://static.fantasykombat.com/images/default-profile-head.png"
                :src="fighterImagesURL+'fighters/images/head-'+fighter.slug+'_160x100.png'|| 'https://static.fantasykombat.com/images/default-profile-head.png'"
              ></v-img>
            </v-avatar>
            <country-flag
              :country="fighter.country"
              size="normal"
              rounded
              class="pick-flag pb-0 mb-0 mx-auto text-center"
            />
          </div>
          <div
            id="js-drop"
            class="drop-zone"
            :class="{pick:pick}"
          >
            <div
              v-for="(element, idx) in 4"
              :key="idx"
              :index="idx"
              :data-dropslot="idx"
              :id="`puId-${idx}`"
              :class="getIndexClass(idx)"
              class="droppable outter"
            >
              <div
                v-if="usedPowerUps[idx]"
                class="remove-pu"
                @click="selectPU($event, idx, usedPowerUps[idx], true)"
              >
                <v-icon color="error">
                  {{ icons.mdiCloseCircleOutline }}
                </v-icon>
              </div>
              <div
                v-if="usedPowerUps[idx]"
                :class="`tier-${usedPowerUps[idx].tier.toLowerCase()}`"
              >
                <div class="inner">
                  <span class="ufc-mov">{{ usedPowerUps[idx].mov }}</span><br>
                  <div class="ufc-name mb-0 pb-0">
                    <div
                      v-resize-text="{maxFontSize: '12px', minFontSize: '10px', delay: 200}"
                      class="ufc-name-text"
                    >
                      {{ usedPowerUps[idx].name|namefirst }}<br>
                      <div class="text-truncate">
                        {{
                          usedPowerUps[idx].name|nameothers
                        }}
                      </div>
                    </div>
                  </div>
                  <div v-if="usedPowerUps[idx].country">
                    <country-flag
                      :country="usedPowerUps[idx].country"
                      size="normal"
                      rounded
                      class="pick-flag pb-0 mb-0 mt-0 pt-0 mx-auto text-center moment-flag"
                    />
                  </div>
                  <svg class="active-octa-svg" viewBox="0 0 75 75" width="104px">
                    <path d="m5,22 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z" stroke="#ffffffb0"
                          stroke-width="2" fill="transparent"/>
                  </svg>
                </div>
              </div>
              <div v-else>
                <v-icon
                  class="add-icon"
                  large
                >
                  {{ icons.mdiPlusCircleOutline }}
                </v-icon>

                <div class="inner">
                </div>
                <svg class="inactive-octa-svg" viewBox="0 0 75 75" width="100px">
                  <path d="m5,22 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z" stroke="#fff"
                        stroke-width="2" fill="#222"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <div class="text-center bottom-pu-actions">
        <div class="text-center mt-2">
          <span class="hidden-md-and-up"><br></span>
          <v-chip
            color="primary"
            outlined
            class="mt-6 mr-1"
          >
            Min Bonus: {{ powerups.totalMin }}
          </v-chip>
          <v-chip
            color="success"
            outlined
            class="mt-6 ml-1"
          >
            Max Bonus: {{ powerups.total }}
          </v-chip>
          <br>
          <v-btn
            outlined
            class="mr-1 d-none"
            @click="childClosePowerUp"
          >
            Close
          </v-btn>
          <v-btn
            outlined
            small
            class="ml-1  d-none"
            @click="resetAll"
          >
            Reset
          </v-btn>
        </div>
      </div>
    </v-card>
    <audio
      ref="audio"
      src='@/assets/sounds/Removing a Plug.mp3'
      id="caudioRemove"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Puzzle_Game_PowerUp_Light_04.wav'
      id="caudioPowerup1"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Puzzle_Game_PowerUp_Light_02.wav'
      id="caudioPowerup2"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Puzzle_Game_PowerUp_Light_03.wav'
      id="caudioPowerup3"
    ></audio>
    <audio
      ref="audio"
      src='@/assets/sounds/Puzzle_Game_Tech_PowerUp_Large_01.wav'
      id="caudioPowerup4"
    ></audio>
  </div>
</template>

<script>
import {
  mdiCloseCircleOutline,
  mdiPlusCircleOutline,
  mdiCheckCircleOutline,
  mdiArrowLeftBoldCircleOutline,
  mdiArrowRightBoldCircleOutline,
  mdiLoading,
  mdiMenuDown,

} from '@mdi/js'

import Draggable from '@/views/components/Draggable.vue'
import dbFunctions from '@/db'

const lookup = require('country-code-lookup')

export default {
  components: {
    Draggable,
  },
  filters: {
    namefirst(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')

      return allnames[0]
    },
    nameothers(value) {
      if (!value) return ''
      const text = value.toString().split('|')[0]
      const allnames = text.split(' ')
      const lastnames = []
      for (let i = 0; i < allnames.length; i += 1) {
        if (i > 0) {
          lastnames.push(allnames[i])
        }
      }

      return lastnames.join(' ')
    },
    splitLine(value) {
      return value.replace(' ', '<br>')
    },
  },
  props: {
    fighter: {},
    pick: Boolean,
    allPicks: Array,
    eventSlug: String,
    date: String,
    status: String,
    fightId: Number,
    fighterIndex: Number,
    side: String,
    allFighters: Object,
    allUsedPowerUps: Object,
  },
  data() {
    return {
      puLoading: true,
      picksLocked: false,
      nfts: [],
      fighterMatchNFTS: [],
      myNFTS: [],
      icons: {
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiPlusCircleOutline,
        mdiArrowLeftBoldCircleOutline,
        mdiArrowRightBoldCircleOutline,
        mdiLoading,
        mdiMenuDown,
      },
      imageBase: '/img',
      fighters: [],
      user: this.$store.state.user,
      puOn: false,
      slideActive: '',
      multipleSelect: true,
      selected: [],
      noPowerUpsUsed: false,
      filter: {
        owner: false,
        ko: false,
        sub: false,
        dec: false,
        tier: false,
        country: false,
      },
      search: '',
      unsubscribe: '',
      loaded: false,
      saving: false,
      dragNdrop: false,
      fullStack: false,
      slideClass: '',
      toggleTselect: false,
      toggleCselect: false,
      country: '',
      movs: '',
      tiers: '',
      owner: '',
      count: 0,
      dapperUser: '',
      usedPowerUps: [],
      updatedAllUsedPowerUps: [],
      selectedPowerUps: [],
      tierList: [
        'All',
        'Contender',
        'Challenger',
        'Fandom',
        'Champion',
        'Ultimate',
      ],
      powerups: {
        owner: 0,
        ko: 0,
        sub: 0,
        dec: 0,
        tier: 0,
        country: 0,
      },
    }
  },
  computed: {
    filteredMyNfts() {
      return this.nfts
        .filter(i => {
          let res = i
          if (this.movs?.toLowerCase() !== 'all') {
            res = !this.movs || i.mov?.toLowerCase() === this.movs?.toLowerCase()
          }

          return res
        }).filter(i => {
          let res = i
          if (this.tiers?.toLowerCase() !== 'all') {
            res = !this.tiers?.toLowerCase() || i.tier?.toLowerCase() === this.tiers?.toLowerCase()
          }

          return res
        }).filter(i => {
          let res = i
          if (this.owner !== 'All') {
            res = !this.owner || i.name?.toLowerCase() === this.owner?.toLowerCase()
          }

          return res
        }).filter(i => {
          let res = i
          if (this.country?.toLowerCase() !== 'All') {
            res = !this.country?.toLowerCase() || i.country?.toLowerCase() === this.country?.toLowerCase()
          }

          return res
        })
        .filter(i => {
          let res = i
          if (this.search) {
            res = Object.entries(res).some(entry => String(entry[1]).toLowerCase().includes(this.search.toLowerCase()))
          }
          const cLookup = lookup.byCountry(this.search)
          if (cLookup) {
            if (i.country.toLowerCase() === cLookup?.iso2.toLowerCase()) {
              res = !cLookup?.iso2.toLowerCase() || i.country.toLowerCase() === cLookup?.iso2.toLowerCase()
            }
          }

          return res
        })
    },
  },
  async mounted() {
    if (this.user.profile.dapperAddress) {
      this.dapperUser = this.user.profile.dapperAddress
    }
    this.usedPowerUps = this.allUsedPowerUps
    this.myPicks = this.allPicks
    await new Promise(() => setTimeout(() => {
      this.init()
    }, 10))
  },
  methods: {
    async init() {
      if (this.usedPowerUps) {
        if (this.usedPowerUps?.powerUpsUsed?.length > 0) {
          this.updatedAllUsedPowerUps = this.usedPowerUps.powerUpsUsed
        } else {
          this.updatedAllUsedPowerUps = this.usedPowerUps.powerUps
        }
        if (this.updatedAllUsedPowerUps) {
          this.fighterMatchNFTS = []
          this.myNFTS = []
          this.filterNfts(this.updatedAllUsedPowerUps)
          this.mapUsedPU()
        } else {
          this.resetAll()
        }
      }
      this.usedPowerUps = []
      if (this.myPicks) {
        const powerUpDict = Object.assign({}, ...this.myPicks.map(x => ({[x.ufc_fight_id]: x})))
        if (powerUpDict[this.fightId]) {
          this.usedPowerUps = powerUpDict[this.fightId]?.powerUps
          this.getPowerUpPoints()
          this.count = this.usedPowerUps.length
          if (this.count.length >= 3) {
            this.fullStack = true
          }
        }
      }
      this.audioWrapperRemove = document.getElementById('caudioRemove')
      this.audioWrapperPowerUp1 = document.getElementById('caudioPowerup1')
      this.audioWrapperPowerUp2 = document.getElementById('caudioPowerup2')
      this.audioWrapperPowerUp3 = document.getElementById('caudioPowerup3')
      this.audioWrapperPowerUp4 = document.getElementById('caudioPowerup4')
      window.$crisp?.push(['set', 'session:event', [[['powerups', {status: 'started'}, 'green']]]])
    },
    savePU(puFields) {
      dbFunctions.savePickem(this.user.uid, this.eventSlug, this.myPicks, this.date, this.status).then(async () => {
        dbFunctions.addCreatePowerUps(this.user.uid, this.user.profile.dapperAddress, puFields, false).then(() => {
          this.getPowerUpPoints()
          this.saving = false
        }).catch(err => {
          console.log(err)
          this.saving = false
        })
      }).catch(err => console.log(err))
    },
    async resetAll() {
      console.log('reset')
      this.puLoading = true
      this.fullStack = false
      this.usedPowerUp = {}
      this.selectedPowerUps = []
      this.updatedAllUsedPowerUps = {}
      this.resetFilter()
      await dbFunctions.updateStrikeNfts(this.user.uid, this.user.profile.dapperAddress, 0, this.allFighters)
      this.$emit('closePowerUp', 'reload', this.count)
      this.puLoading = false
    },
    mapUsedPU() {
      if (Object.keys(this.updatedAllUsedPowerUps).length) {
        this.selectedPowerUps = Object.fromEntries(
          this.updatedAllUsedPowerUps.map(item => [item.id, {
            item,
          }]),
        )
      } else {
        this.selectedPowerUps = []
      }
    },
    getPowerUpPoints() {
      if (document.getElementById('tierChip')) {
        document.getElementById('tierChip').classList.remove('active')
      }
      this.powerups = {
        owner: 0,
        ko: 0,
        sub: 0,
        dec: 0,
        tier: 0,
        country: 0,
        total: 0,
        totalMin: 0,
      }

      // points are also in serverless applyPickPoints function
      for (let i = 0; i < this.usedPowerUps.length; i += 1) {
        if (this.usedPowerUps[i].mov === 'SUB') {
          this.powerups.sub += 2
        } else if (this.usedPowerUps[i].mov === 'KO/TKO') {
          this.powerups.ko += 2
        } else if (this.usedPowerUps[i].mov.toLowerCase() === 'dec' || this.usedPowerUps[i].mov.toLowerCase() === 'grappling' || this.usedPowerUps[i].mov.toLowerCase() === 'striking') {
          this.powerups.dec += 2
        }
        if (this.usedPowerUps[i].name === this.fighter.name) {
          this.powerups.owner += 5
        }
        if (this.usedPowerUps[i].tier?.toLowerCase() === 'ultimate') {
          this.powerups.tier += 7
        } else if (this.usedPowerUps[i].tier?.toLowerCase() === 'champion') {
          this.powerups.tier += 4
        } else if (this.usedPowerUps[i].tier?.toLowerCase() === 'challenger') {
          this.powerups.tier += 2
        } else if (this.usedPowerUps[i].tier?.toLowerCase() === 'fandom') {
          this.powerups.tier += 3
        } else if (this.usedPowerUps[i].tier?.toLowerCase() === 'contender') {
          this.powerups.tier += 1
        }
        if (this.usedPowerUps[i].country === this.fighter.country) {
          this.powerups.country += 1
        }

        const largest = Math.max(this.powerups.sub, this.powerups.ko, this.powerups.dec)
        this.powerups.total = this.powerups.owner + this.powerups.tier + this.powerups.country + largest
        const min = Math.min(this.powerups.sub, this.powerups.ko, this.powerups.dec)
        this.powerups.totalMin = this.powerups.owner + this.powerups.tier + this.powerups.country + min
        if (document.getElementById('tierChip')) {
          document.getElementById('tierChip').classList.add('active')
        }
      }
    },
    filterNfts(nfts) {
      const used = []
      const remaining = []
      const ultimate = []
      const champ = []
      const country = []
      for (let i = 0; i < nfts.length; i += 1) {
        if (nfts[i].status === 'used' && !this.loaded) {
          used.push(nfts[i])
        } else if (nfts[i].name === this.fighter.name) {
          this.fighterMatchNFTS.push(nfts[i])
        } else if (nfts[i].tier) {
          if (nfts[i].tier.toLowerCase() === 'ultimate') {
            ultimate.push(nfts[i])
          } else if (nfts[i].tier.toLowerCase() === 'champion') {
            champ.push(nfts[i])
          } else if (nfts[i].tier.toLowerCase() === 'fandom') {
            this.myNFTS.push(nfts[i])
          } else if (nfts[i].tier.toLowerCase() === 'challenger') {
            this.myNFTS.push(nfts[i])
          } else if (nfts[i].country === this.fighter.country) {
            country.push(nfts[i])
          } else {
            remaining.push(nfts[i])
          }
        } else {
          remaining.push(nfts[i])
        }
      }

      remaining.sort((a, b) => a.name.localeCompare(b.name)) // order by name
      this.nfts = [...this.fighterMatchNFTS, ...ultimate, ...champ, ...this.myNFTS.reverse(), ...country, ...remaining, ...used]
      this.puLoading = false
    },
    countryChange() {
      this.country = ''
      this.toggleCselect = !this.toggleCselect
    },
    getIndexClass(idx) {
      let c = 'unused'
      if (idx <= 3) {
        if (this.usedPowerUps[idx]) {
          c = `used octagon-${idx} drop--me`
        } else {
          c = `used octagon-${idx}`
        }
      }

      return c
    },
    childClosePowerUp() {
      this.$emit('closePowerUp', false, this.count)
    },
    async selectPU(e, idx, el, remove) {
      if (!this.saving) {
        const pu = el
        const powerUpArray = []

        if (this.status === 'Upcoming') {
          this.picksLocked = false
          if (this.user.uid) {
            if (remove) {
              this.saving = true
              this.audioWrapperRemove.currentTime = 0
              this.audioWrapperRemove.volume = 0.1
              this.audioWrapperRemove.play()
              for (let i = 0; i < this.updatedAllUsedPowerUps.length; i += 1) {
                if (this.updatedAllUsedPowerUps[i].id === el.id) {
                  this.updatedAllUsedPowerUps[i].status = ''
                  this.updatedAllUsedPowerUps[i].fighter = ''
                }
              }
              for (let i = 0; i < Object.entries(this.usedPowerUps).length; i += 1) {
                if (Object.entries(this.usedPowerUps)[i][1].id !== el.id) {
                  powerUpArray.push(Object.entries(this.usedPowerUps)[i][1])
                }
              }
              this.myPicks[this.fighterIndex] = {
                picked: this.side,
                ufc_fight_id: this.fightId,
                powerUps: powerUpArray,
              }
              this.usedPowerUps = powerUpArray
              this.count = this.usedPowerUps.length
              const puFields = {powerUpsUsed: this.updatedAllUsedPowerUps}
              this.savePU(puFields)
              this.fullStack = false
              if (this.count >= 4) {
                this.fullStack = true
              }
            } else if (el.status === 'used' || this.selectedPowerUps[el.id].item.status === 'used') {
              if (document.getElementById(`used-${el.id}`)) {
                document.getElementById(`used-${el.id}`).classList.add('red--text')
                setTimeout(() => {
                  if (document.getElementById(`used-${el.id}`)) {
                    document.getElementById(`used-${el.id}`).classList.remove('red--text')
                  }
                }, 1500)
              }
            } else if (el.status !== 'used') {
              if (this.count >= 4) {
                this.fullStack = true
              } else {
                this.saving = true
                if (el.tier.toLowerCase() === 'champion' || el.tier.toLowerCase() === 'ultimate') {
                  this.audioWrapperPowerUp4.currentTime = 0
                  this.audioWrapperPowerUp4.volume = 0.1
                  this.audioWrapperPowerUp4.play()
                } else if (el.tier.toLowerCase() === 'fandom') {
                  this.audioWrapperPowerUp3.currentTime = 0
                  this.audioWrapperPowerUp3.volume = 0.1
                  this.audioWrapperPowerUp3.play()
                } else if (el.tier.toLowerCase() === 'challenger') {
                  this.audioWrapperPowerUp2.currentTime = 0
                  this.audioWrapperPowerUp2.volume = 0.1
                  this.audioWrapperPowerUp2.play()
                } else {
                  this.audioWrapperPowerUp1.currentTime = 0
                  this.audioWrapperPowerUp1.volume = 0.1
                  this.audioWrapperPowerUp1.play()
                }
                if (document.getElementById(`puId-${idx}`)) {
                  document.getElementById(`puId-${idx}`).classList.add('hide')
                }
                pu.status = 'used'
                pu.fighter = this.fighter.fighter_id
                this.usedPowerUps.push(pu)
                this.count = this.usedPowerUps.length
                for (let i = 0; i < Object.entries(this.usedPowerUps).length; i += 1) {
                  powerUpArray.push(Object.entries(this.usedPowerUps)[i][1])
                }
                this.myPicks[this.fighterIndex] = {
                  picked: this.side,
                  ufc_fight_id: this.fightId,
                  powerUps: powerUpArray,
                }
                const puFields = {
                  powerUpsUsed: this.updatedAllUsedPowerUps,
                }
                if (document.getElementById(`puId-${idx}`)) {
                  setTimeout(() => {
                    if (document.getElementById(`puId-${idx}`)) {
                      document.getElementById(`puId-${idx}`).classList.remove('hide')
                    }
                  }, 4000)
                }
                this.savePU(puFields)
                this.fullStack = false
                if (this.count >= 4) {
                  this.fullStack = true
                }
              }
            }
          }
        } else {
          this.picksLocked = true
          document.getElementById('pulocked').classList.add('red--text')
          setTimeout(() => {
            document.getElementById('pulocked').classList.remove('red--text')
          }, 500)
        }
      }
    },
    resetFilter() {
      this.toggleTselect = false
      this.owner = ''
      this.movs = ''
      this.tiers = ''
      this.country = ''
      this.filter = {
        owner: false,
        ko: false,
        sub: false,
        dec: false,
        tier: false,
        country: false,
      }
    },
    fOwner(fighterName) {
      if (this.filter.owner) {
        this.filter.owner = false
        this.owner = ''
      } else {
        this.filter.owner = true
        this.owner = fighterName
      }
    },
    fCountry(fighterCountry) {
      if (this.filter.country) {
        this.filter.country = false
        this.country = ''
      } else {
        this.filter.country = true
        this.country = fighterCountry
      }
    },
    fMovs(mov) {
      this.filter = {
        ko: false,
        sub: false,
        dec: false,
      }
      this.movs = ''
      if (mov === 'KO') {
        if (this.filter.ko) {
          this.movs = ''
          this.filter.ko = false
        } else {
          this.movs = 'KO/TKO'
          this.filter.ko = true
        }
      } else if (mov?.toLowerCase() === 'sub') {
        if (this.filter.sub) {
          this.movs = ''
          this.filter.sub = false
        } else {
          this.movs = 'SUB'
          this.filter.sub = true
        }
      } else if (mov?.toLowerCase() === 'dec' || 'striking' || 'grappling') {
        if (this.filter.dec) {
          this.movs = ''
          this.filter.dec = false
        } else {
          this.movs = 'DEC'
          this.filter.dec = true
        }
      }
    },
  },
}
</script>
<style>
.v-chip.active span.pu-num {
  color: #56ca00;
}

.savingstate svg path, .savingstate inner {
  filter: grayscale(1);
  opacity: 0.7;
}

#powerups-comp .close {
  right: 0;
  position: absolute;
}

.power-up-stack {
  background: url('~@/assets/images/misc/purplecomb.webp') no-repeat;
  background-size: contain;
  padding: 0;
  margin: -24px 20px -20px -20px;
  width: 820px;
  padding: 20px;
  position: relative;
  display: inline-block;
  margin-right: auto;
  top: 157px;
}

.mobile .power-up-stack {
  width: 110%;
  top: 150px;
  background: transparent;
}

#powerups-comp .pu-chips {
  text-align: center;
  width: 100%;
}

.mobile #powerups-comp .v-card__text {
  margin-top: -20px;
}

.mobile #powerups-comp .pu-chips {
  text-align: center;
  margin-left: -15px;
  padding: 0;
  width: 110%;
}

.mobile .powerup-inst.mt-6.mb-1 {
  top: 10px;
}

#powerups-comp .pu-card {
  height: 730px
}

#powerups-comp .v-avatar.pick-avatar.borderwin {
  border: 3px solid #56ca00 !important;
}

.mobile #powerups-comp .pu-card {
  height: 90%
}

.saving {
  text-align: right;
  position: relative;
  top: 182px;
  z-index: 999;
  width: 100%;
  margin-bottom: -24px;
}

.mobile .saving {
  top: 150px;
}

.bottom-pu-actions {
  bottom: 89px;
  position: relative;
  display: block;
}

.mobile .bottom-pu-actions {
  bottom: 108px;
  position: relative;
  display: block;
}

.pu-wait {
  margin-top: 40px;
  margin-bottom: -40px;
}

.pu-spinner {
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#powerups-comp .v-slide-group {
  z-index: 3;
  height: 120px;
  width: 800px;
  background: #000;
  margin: -37px 0 0 6px;
  padding-top: 29px;
  height: 176px;
  border-top: 2px solid #666;
  border-bottom: 2px solid #666;
}

.powerup-inst {
  z-index: 4;
  color: #fff;
  display: block;
  text-align: center;
  margin: auto;
  position: relative;
  top: 9px;
  width: 220px;
}

.v-slide-group__content {
  padding-left: 10px;
}

.v-slide-group.dragenabled {
  height: 450px;
}

.selectedPowerUps.dndselected {
  top: -15px;
}

.tier-spacer {
  opacity: 0;
  float: right;
  position: absolute
}

.pu-country .v-icon {
  fill: #fff;
}

.v-slide-group__prev {
  top: 11px;
  position: absolute;
  left: 8px;
  transform: scale(1.5);
}

.v-slide-group__next {
  top: 10px;
  position: absolute;
  right: 8px;
  transform: scale(1.5);
}

.country-select {
  width: 80%;
}

.country-pu .country-select .v-input__icon.v-input__icon--append svg {
  fill: #fff !important;
  margin-top: 3px;
  margin-left: -23px;
  height: 14px;
  opacity: 0;
}

.country-pu input {
  font-size: 15px;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
}

.v-input.tier-select.v-select {
  width: 0;
  left: 5px;
}

#powerups-comp .moment-flag {
  margin: auto;
  display: block;
  position: relative;
  top: 4.5px;
  transform: scale(0.4);
  -ms-transform: scale(0.4);
  -webkit-transform: scale(0.35);
  -moz-transform: scale(0.4);
  left: 1px;
  z-index: 5;
}

#powerups-comp .used .moment-flag {
  top: 6px !important;
}

.pu-complete {
  margin-bottom: -118px;
  margin-top: 23px;
}

.pu-fin-btn {
  position: relative;
  top: 84px;
  margin: auto;
  display: block;
  text-align: center;
}

.choose-power-up-fighter .v-card__title {
  min-height: 43px;
  text-align: center;
  display: inline-block;
}

.dnd-switch {
  position: absolute;
  z-index: 999;
  right: 10px;
  bottom: 0;
}

.focus-card {
  top: 88px;
  width: 80px;
  height: 80px;
  height: 80px;
  position: relative;
  z-index: 3;
  margin: auto;
}

#powerups-comp .focus-card span.pick-flag.normal-flag {
  position: absolute;
  right: 14px;
  bottom: -9px;
  transform: scale(0.4);
}

.unused {
  left: 20px;
}

.selectedPowerUps {
  position: relative;
  width: 360px;
  top: 15px;
}

.dropslot-0 {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 321px;
}

.selectedPU.dropslot-0 {
  top: 106px !important;

}

.dropslot-1 {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 247px;
}

.selectedPU.dropslot-1, .selectedPU.dropslot-2 {
  top: 181.5px;
}

.dropslot-2 {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 394px;
}

.dropslot-3 {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 322px;
}

.selectedPU.dropslot-3 {
  top: 256px;
}

.dropslot-4 {
  position: absolute;
  z-index: 3;
  top: 1px;
  left: 246px;
}

.selectedPU.dropslot-4 {
  top: 180px;
}

.used.octagon-0 {
  top: -66px;
  margin: auto;
}

.used.octagon-1 {
  right: -67px;
  top: -95.5px;
  margin: auto;
}

.used.octagon-2 {
  top: -127px;
  margin: auto;
}

.used.octagon-3 {
  left: -67px;
  top: -295.4px;
  margin: auto;
}

.octagon-3 svg.enabled-octa-svg {
  margin-top: -86px;
  height: 101px;
  width: 104px;
}

svg.inactive-octa-svg {
  margin-top: -39px;
}

.draggable {
  margin: 0px;
  cursor: pointer;
}

.dragenabled .draggable {
  cursor: grab !important;
}

.powerUpList {
  position: absolute;
  width: 100%;
  margin-left: -26px;
  margin-top: -5px;

}

.mobile .powerUpList {
  margin-top: -4px;
}

div#js-drop.pick {
  margin: auto;
  display: block;
  position: relative;
}

.v-chip.active {
  border: 2px solid #ffffffb0;
}

.pu-country .v-select__selections {
  font-size: 15px;
}

.v-chip.kof, .v-chip.subf, .v-chip.decf, .v-chip.tierf, .v-chip.countryf, .v-chip.ownerf {
  border: 2px solid #1abadb;
}

p.powerup-inst {
  margin: auto;
  display: block;
  position: relative;
  text-align: center;
}

.remove-pu {
  position: absolute;
  border-radius: 50%;
  left: 38px;
  top: 36px;
  opacity: 0;

}

.dropslot-4 .remove-pu {
  left: 0;
}

.dropslot-3 .remove-pu {
  top: 103px;
}

.selectedPU {
  opacity: 0.4;
}

#powerups-comp .selectedPU .status {
  text-align: center;
  margin-top: 15px;
  opacity: 0;
}

#powerups-comp .red--text {
  color: #b94545;
  opacity: 1;
  animation: fadeIn7 0.6s;
}

#powerups-comp .hide {
  animation: fadeOutShrink 0.4s;
}

.droppable:hover .remove-pu, .remove-pu:hover {
  opacity: 0.7;
  margin-top: 0;
  animation: fadeIn7 0.6s;
  cursor: pointer;
  background: #fff;
  z-index: 5;
}

@keyframes fadeIn7 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInGrow {
  from {
    transform: scale(0.5);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOutShrink {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.5);
    opacity: 0.5;
    display: none;
  }
}

.powerUpList.hidden {
  opacity: 0;
  visibility: hidden;
}

.pu-complete {
  right: 27px;
  bottom: 29px;
  position: absolute;
  margin: auto;
  display: inline-block;
}

.droppable .inner {
  stroke: #fff;
}

.tier-contender .inner {
  background-image: url('https://static.fantasykombat.com/images/contender-solid.png');
  background-size: cover
}

.tier-challenger .inner {
  background-image: url('https://static.fantasykombat.com/images/challenger-solid.png');
  background-size: cover
}

.tier-fandom .inner {
  background-image: url('https://static.fantasykombat.com/images/fandom-solid.png');
  background-size: cover
}

.tier-champion .inner {
  background-image: url('https://static.fantasykombat.com/images/champion-solid.png');
  background-size: cover
}

.tier-ultimate .inner {
  background-image: url('https://static.fantasykombat.com/images/ultimate-solid.png');
  background-size: cover
}

.can--catch.outter:after, .can--catch.outter:before {
  background: #1abadb !important;
}

.can--catch .add-icon {
  color: #1abadb;
}

.add-icon {
  left: 32px;
  top: 25px;
}

.ff .inner {
  background-size: cover !important;
}

.inner {
  fill: #1abadb;
}

.drop-zone .drop--me .inner {
  width: 100px;
  height: 100px;
  margin-top: -5px;
}

.drop-zone .drop--me {
  animation: fadeInGrow 0.5s;
}

.outter {
  position: relative;
  top: 13px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  z-index: 0;

}

.outter svg {
  outline: transparent;
}

.outter svg.active-octa-svg {
  position: absolute;
  top: -3px;
  left: -1.5px;
}

#powerups-comp #octogon .pick-flag {
  position: relative;
  left: 24px;
  bottom: 9px;
}

.mini-preview {
  margin: auto;
  display: block;
  position: relative;
  left: 45px;
  top: -30px;
  opacity: 0.8;
  width: 5px;
}

.mini-preview:hover {
  opacity: 1;
}

#powerups-comp span.ufc-mov {
  display: block;
  margin: auto;
  position: relative;
  text-align: center;
  color: #fff;
  font-weight: 800;
  font-size: 10px;
  top: 22px;
  margin-bottom: -15px;
  left: 0.5px;
}

#powerups-comp div.ufc-name {
  display: block;
  z-index: 2;
  position: relative;
  background: #222;
  width: 68px;
  height: 30px;
  padding: 0;
  border-radius: 2px;
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
  margin: auto;
  top: 15px;
  left: 0px;
}

#powerups-comp .used div.ufc-name {
  width: 70px;
  top: 16px;
  left: 0.5px;
  height: 31px;
}

#powerups-comp div.ufc-name-text {
  width: 70px !important;
  display: block;
  margin: auto;
  text-align: center;
  color: #fff;
  font-weight: 400;
  line-height: 13px;
  padding: 1px;
}

.no-pu-match {
  text-align: center;
  position: relative;
  display: block;
  margin: auto;
}

.v-chip.Contender {
  border: 2px solid #d00055;
  box-shadow: 0px 0px 3px #d00055;
}

.v-chip.Challenger {
  border: 2px solid #cdcdcd;
  box-shadow: 0px 0px 3px #cdcdcd;
}

.v-chip.Fandom {
  border: 2px solid #942ae3;
  box-shadow: 0px 0px 3px #777;
}

.v-chip.Champion {
  border: 2px solid #fcd07f;
  box-shadow: 0px 0px 3px #fcd07f;
}

.v-chip.Ultimate {
  border: 2px solid green;
  box-shadow: 0px 0px 3px green;
}

.bottom-pu-actions .v-chip__content {
  color: #fff;
}

.bottom-pu-actions .v-chip.v-chip--outlined.theme--dark {
  background: #322d4b !important;
}
</style>
