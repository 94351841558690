var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.selectedLinkedFighter)+" "),_c('v-btn',{on:{"click":function($event){return _vm.linkFighter(_vm.selectedLinkedFighter, _vm.slug)}}},[_vm._v(" Link Fighter ")]),_c('v-autocomplete',{staticClass:"mx-4",attrs:{"loading":_vm.loadingFighters,"items":_vm.fighterItems,"search-input":_vm.fighterSearch,"item-text":"name","cache-items":"","flat":"","hide-no-data":"","hide-details":"","deletable-chips":true,"multiple":false,"label":"Search Fighters?"},on:{"update:searchInput":function($event){_vm.fighterSearch=$event},"update:search-input":function($event){_vm.fighterSearch=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var fighterItems = ref.fighterItems;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":fighterItems,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(item.name)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{on:{"click":function($event){_vm.selectedLinkedFighter=item}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.slug)}})],1)]}}]),model:{value:(_vm.allFighters),callback:function ($$v) {_vm.allFighters=$$v},expression:"allFighters"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }